import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { patchMeasuredRolloutMetricStates } from './internal/patchMeasuredRolloutMetricStates';
import { measuredRollouts } from './internal/queries';

export const useUpdateMeasuredRolloutMetricStates = createMutationHook({
  mutationFn: patchMeasuredRolloutMetricStates,
  onSuccess: async (data, { projectKey, environmentKey, flagKey, measuredRolloutId }) => {
    const queryClient = getQueryClient();

    await queryClient.invalidateQueries({
      queryKey: measuredRollouts.detail({ projectKey, flagKey, environmentKey, measuredRolloutId }).queryKey,
    });
  },
});
