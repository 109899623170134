import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export async function getMeasuredRolloutResults({
  projectKey,
  flagKey,
  environmentKey,
  measuredRolloutId,
  metricKey,
}: {
  projectKey: string;
  flagKey: string;
  environmentKey: string;
  measuredRolloutId: string;
  metricKey: string;
}) {
  return reactQueryResponseAdapter(
    getClient().GET(
      '/api/v2/projects/{projectKey}/flags/{flagKey}/environments/{environmentKey}/measured-rollouts/{measuredRolloutId}/metric-results/{metricKey}',
      {
        headers: { 'LD-API-Version': 'beta' },
        params: {
          path: { projectKey, flagKey, environmentKey, measuredRolloutId, metricKey },
        },
      },
    ),
  );
}
