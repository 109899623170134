import { GetMetricsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export type GetMetricsQueryParams = Omit<GetMetricsOpenAPIQueryParams, 'expand' | 'sort'> & {
  expand?: Array<'experimentCount'>;
  query?: string;
  sort?: 'name' | '-name' | 'createdAt' | '-createdAt';
};

export function querySerializer(queryParams: GetMetricsQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.expand) {
    searchParams.set('expand', queryParams.expand.join(','));
  }

  if (queryParams.query) {
    searchParams.set('q', queryParams.query);
  }

  if (queryParams.limit) {
    searchParams.set('limit', queryParams.limit.toString());
  }

  if (queryParams.offset) {
    searchParams.set('offset', queryParams.offset.toString());
  }

  if (queryParams.sort) {
    searchParams.set('sort', queryParams.sort);
  }

  return searchParams.toString();
}
