import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { deleteFeaturePreviewEnrollment } from './internal/deleteFeaturePreviewEnrollment';
import { featurePreviews } from './internal/queries';

export const useWithdrawFromFeaturePreview = createMutationHook({
  mutationFn: deleteFeaturePreviewEnrollment,
  onSuccess: (data, {}) => {
    const queryClient = getQueryClient();
    queryClient.setQueryData(featurePreviews.list.queryKey, data);
  },
});
