import { getClient, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export type CreateSegmentInput = schemas['SegmentBody'];

export async function postSegment({
  projectKey,
  environmentKey,
  input,
}: {
  projectKey: string;
  environmentKey: string;
  input: CreateSegmentInput;
}) {
  return reactQueryResponseAdapter(
    getClient().POST('/api/v2/segments/{projectKey}/{environmentKey}', {
      params: {
        path: { projectKey, environmentKey },
      },
      body: input,
    }),
  );
}
