import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { JSONPatch } from './types';

export async function patchApplication({
  applicationKey,
  input,
}: {
  applicationKey: string;
  input: { comment?: string; patch: JSONPatch };
}) {
  return reactQueryResponseAdapter(
    getClient().PATCH('/api/v2/applications/{applicationKey}', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { applicationKey },
      },
      // @ts-expect-error Our OpenAPI spec generator doesn't support the JSON patch format yet
      body: input,
    }),
  );
}
