import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export async function patchMemberQuickstartStatus({ status }: { status: string }) {
  return reactQueryResponseAdapter(
    getClient().PATCH('/internal/profile/deescalate', {
      body: [
        {
          op: 'replace',
          path: '/quickstartStatus',
          value: status,
        },
      ],
    }),
  );
}
