import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { serializeReleasePipelineFilterParam } from './serializeReleasePipelineFilterParam';
import { GetReleasePipelinesParams } from './types';

export async function getReleasePipelines({
  projectKey,
  params,
}: {
  projectKey: string;
  params?: GetReleasePipelinesParams;
}) {
  return reactQueryResponseAdapter(
    getClient().GET('/api/v2/projects/{projectKey}/release-pipelines', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: {
          projectKey,
        },
        // @ts-expect-error Our OpenAPI spec generator doesn't support the `filter` parameter serialization method yet
        query: params,
      },
      // @ts-expect-error we hardcode the type here to work around the limitation above
      querySerializer(queryParams: GetReleasePipelinesParams) {
        const searchParams = new URLSearchParams();

        if ((queryParams.limit ?? 0) > 0) {
          searchParams.set('limit', String(queryParams.limit));
        }

        if ((queryParams.offset ?? 0) > 0) {
          searchParams.set('offset', String(queryParams.offset));
        }

        if (queryParams.filter) {
          const serializedFilter = serializeReleasePipelineFilterParam(queryParams.filter);
          if (serializedFilter) {
            searchParams.set('filter', serializedFilter);
          }
        }

        return searchParams.toString();
      },
    }),
  );
}
