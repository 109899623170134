import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export async function deleteApplication({ applicationKey }: { applicationKey: string }) {
  return reactQueryResponseAdapter(
    getClient().DELETE('/api/v2/applications/{applicationKey}', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { applicationKey },
      },
    }),
  );
}
