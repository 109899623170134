import { getNextSubscription as getNextModernSubscription } from '../internal/modernSubscription';
import { Subscription } from '../types';

import { isModernSubscription } from './isModernSubscription';

export function getNextSubscription(subscription: Subscription) {
  if (isModernSubscription(subscription)) {
    return getNextModernSubscription(subscription);
  }
  // not supported for legacy subscriptions
  return;
}
