import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { GetPendingRequestsParams } from './types';

export async function getPendingRequests({ kind, params }: { kind: string; params?: GetPendingRequestsParams }) {
  return reactQueryResponseAdapter(
    getClient().GET('/api/v2/account/pending-requests/{kind}', {
      params: {
        path: { kind },
        query: params,
      },
      // @ts-expect-error we are purposely overriding the sort type in ./types
      querySerializer: (queryParams: GetPendingRequestsParams) => {
        const searchParams = new URLSearchParams();
        if (queryParams.sort) {
          searchParams.set('sort', queryParams.sort);
        }

        if (queryParams.limit) {
          searchParams.set('limit', queryParams.limit.toString());
        }

        if (queryParams.offset) {
          searchParams.set('offset', queryParams.offset.toString());
        }

        return searchParams.toString();
      },
    }),
  );
}
