import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { putShortcutOrdering } from './internal/putShortcutOrdering';
import { shortcuts } from './internal/queries';

export const useUpdateShortcutOrdering = createMutationHook({
  mutationFn: putShortcutOrdering,
  onMutate: async (variables) => {
    const queryOptions = shortcuts.list({ projectKey: variables.projectKey });
    const queryKey = queryOptions.queryKey;

    type QueryData = Awaited<ReturnType<(typeof queryOptions)['queryFn']>>;

    await getQueryClient().cancelQueries({ queryKey });

    const previousShortcuts = getQueryClient().getQueryData<QueryData>(queryKey);

    getQueryClient().setQueryData<QueryData>(queryKey, (old) => {
      if (old === undefined) {
        return;
      }

      const shortcutKeys = variables.body.shortcutKeys;

      if (!shortcutKeys) {
        return old;
      }

      const sortByReorder = (a: { key: string }, b: { key: string }) => {
        const indexA = shortcutKeys.indexOf(a.key);
        const indexB = shortcutKeys.indexOf(b.key);
        return indexA - indexB;
      };
      return {
        ...old,
        items: [...old.items].sort(sortByReorder),
      };
    });

    return { previousShortcuts };
  },
  onError: (error, variables, context) => {
    const queryOptions = shortcuts.list({ projectKey: variables.projectKey });
    const queryKey = queryOptions.queryKey;
    getQueryClient().setQueryData(queryKey, context?.previousShortcuts);
  },
  onSuccess: async (data, variables) => {
    const queryOptions = shortcuts.list({ projectKey: variables.projectKey });
    const queryKey = queryOptions.queryKey;
    await getQueryClient().invalidateQueries({ queryKey });
  },
});
