import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export async function triggerFlagImportIntegration({
  projectKey,
  integrationKey,
  integrationId,
}: {
  projectKey: string;
  integrationKey: string;
  integrationId: string;
}) {
  return reactQueryResponseAdapter(
    getClient().POST(
      '/api/v2/integration-capabilities/flag-import/{projectKey}/{integrationKey}/{integrationId}/trigger',
      {
        headers: { 'LD-API-Version': 'beta' },
        params: {
          path: { projectKey, integrationKey, integrationId },
        },
      },
    ),
  );
}
