import { getClient, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export type PostMigrationSafetyInput = schemas['flagSempatch'];

export async function getMigrationSafetyIssues({
  projectKey,
  flagKey,
  environmentKey,
  query,
}: {
  projectKey: string;
  flagKey: string;
  environmentKey: string;
  query: PostMigrationSafetyInput;
}) {
  return reactQueryResponseAdapter(
    getClient().POST(
      '/api/v2/projects/{projectKey}/flags/{flagKey}/environments/{environmentKey}/migration-safety-issues',
      {
        headers: { 'LD-API-Version': 'beta' },
        params: {
          path: { projectKey, flagKey, environmentKey },
        },
        body: query,
      },
    ),
  );
}
