import { createSuspenseQueryHook } from './internal/createSuspenseQueryHook';
import { metricGroups } from './internal/queries';
import { GetMetricGroupParams } from './internal/types';

export const useSuspenseMetricGroup = createSuspenseQueryHook(
  ({
    projectKey,
    metricGroupKey,
    params,
  }: {
    projectKey: string;
    metricGroupKey: string;
    params?: GetMetricGroupParams;
  }) => metricGroups.detail({ projectKey, metricGroupKey, params }),
);
