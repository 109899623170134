export type ReleasePipelineFilter = {
  query?: string;
};

export function serializeReleasePipelineFilterParam(value: ReleasePipelineFilter) {
  const filter: string[] = [];

  if (value.query) {
    filter.push(`query:${value.query}`);
  }

  return filter.join(',');
}
