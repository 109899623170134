import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { deleteFlagMeasuredRolloutConfiguration } from './internal/deleteFlagMeasuredRolloutConfiguration';
import { flags } from './internal/queries';

export const useDeleteFlagMeasuredRolloutConfiguration = createMutationHook({
  mutationFn: deleteFlagMeasuredRolloutConfiguration,
  onSuccess: async (data, { projectKey, flagKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: flags.measuredRolloutConfiguration(projectKey, flagKey).queryKey });
  },
});
