import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { PutDeviceCodeInput } from './types';

export async function putDeviceCode({ deviceCode, input }: { deviceCode: string; input: PutDeviceCodeInput }) {
  return reactQueryResponseAdapter(
    getClient().PUT('/internal/device/{deviceCode}', {
      body: input,
      params: {
        path: {
          deviceCode,
        },
      },
    }),
  );
}
