import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { deleteFlagLink } from './internal/deleteFlagLink';
import { flags } from './internal/queries';

export const useDeleteFlagLink = createMutationHook({
  mutationFn: deleteFlagLink,
  onSuccess: async (data, { projectKey, flagKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({
      queryKey: flags.detail({ projectKey, flagKey })._ctx.links.queryKey,
    });
  },
});
