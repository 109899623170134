import { createQueryPrefetcher } from './internal/createQueryPrefetcher';
import { releasePipelines } from './internal/queries';
import { GetReleaseProgressionsParams } from './internal/types';

export const prefetchReleaseProgressions = createQueryPrefetcher(
  ({
    projectKey,
    releasePipelineKey,
    params,
  }: {
    projectKey: string;
    releasePipelineKey: string;
    params?: GetReleaseProgressionsParams;
  }) => releasePipelines.detail({ projectKey, releasePipelineKey })._ctx.releaseProgressions({ params }),
);
