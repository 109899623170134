import { schemas } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { projectsDetail } from './internal/queries';
import { GetProjectParams } from './internal/types';

export function initializeProject({
  projectKey,
  params,
  project,
}: {
  projectKey: string;
  params?: GetProjectParams;
  project: schemas['Project'];
}) {
  getQueryClient().setQueryData(projectsDetail({ projectKey, query: params }).queryKey, project);
}
