import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { serializeMemberListFilterParam } from './serializeMemberListFilterParam';
import { GetMembersParams } from './types';

export async function getMembers({ params }: { params?: GetMembersParams }, { signal }: { signal?: AbortSignal } = {}) {
  return reactQueryResponseAdapter(
    getClient().GET('/api/v2/members', {
      headers: { 'Ld-Api-Version': '20160426' },
      params: {
        // @ts-expect-error expects filter to be string
        query: params,
      },
      signal,
      // @ts-expect-error expects sort to be simple string type
      querySerializer(queryParams: GetMembersParams) {
        const searchParams = new URLSearchParams();

        if (queryParams.limit !== undefined && queryParams.limit > 0) {
          searchParams.set('limit', queryParams.limit.toString());
        }

        if (queryParams.offset !== undefined && queryParams.offset > 0) {
          searchParams.set('offset', queryParams.offset.toString());
        }

        if (queryParams.filter) {
          const serializedFilter = serializeMemberListFilterParam(queryParams.filter);
          if (serializedFilter) {
            searchParams.set('filter', serializedFilter);
          }
        }

        if (queryParams.sort) {
          searchParams.set('sort', queryParams.sort);
        }

        if (queryParams.expand) {
          searchParams.set('expand', queryParams.expand);
        }

        return searchParams.toString();
      },
    }),
  );
}
