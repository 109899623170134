import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { PatchMetricStateBody } from './types';

export async function patchMeasuredRolloutMetricStates({
  measuredRolloutId,
  projectKey,
  environmentKey,
  metricKey,
  input,
}: {
  measuredRolloutId: string;
  projectKey: string;
  environmentKey: string;
  flagKey: string;
  metricKey: string;
  input: PatchMetricStateBody;
}) {
  return reactQueryResponseAdapter(
    getClient().PATCH(
      '/internal/projects/{projectKey}/environments/{environmentKey}/measured-rollouts/{measuredRolloutId}/metric-states/{metricKey}',
      {
        headers: { 'LD-API-Version': 'beta' },
        params: {
          path: {
            projectKey,
            environmentKey,
            measuredRolloutId,
            metricKey,
          },
        },
        body: input,
      },
    ),
  );
}
