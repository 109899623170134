import { getClient, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export type GenerateExperimentInput = schemas['GenerateExperimentPost'];

export type GenerateExperimentResponse = schemas['AIGeneratedExperimentConfig'];

export type GeneratedItem = schemas['AIGeneratedItem'];

export type SuggestedItem = schemas['SuggestedItem'];

async function generateExperiment({
  projectKey,
  environmentKey,
  input,
}: {
  projectKey: string;
  environmentKey: string;
  input: GenerateExperimentInput;
}) {
  const url = '/api/v2/ai/projects/{projectKey}/environments/{environmentKey}/generate-experiment';
  return reactQueryResponseAdapter(
    getClient().POST(url, {
      headers: { 'LD-API-Version': 'beta' },
      params: { path: { projectKey, environmentKey } },
      body: input,
    }),
  );
}

export { generateExperiment };
