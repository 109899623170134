import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { postFlagCleanup } from './internal/postFlagCleanup';
import { flags } from './internal/queries';

export const useFlagCleanup = createMutationHook({
  mutationFn: postFlagCleanup,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: flags.getCleanup._def });
    await queryClient.invalidateQueries({ queryKey: flags.cleanup._def });
  },
});
