import { createSuspenseQueryHook } from './internal/createSuspenseQueryHook';
import { segments } from './internal/queries';
import { GetSegmentsParams } from './internal/types';

export const useSuspenseSegments = createSuspenseQueryHook(
  ({
    projectKey,
    environmentKey,
    params,
    batch,
  }: {
    projectKey: string;
    environmentKey: string;
    params?: GetSegmentsParams;
    batch?: boolean;
  }) => segments.list({ projectKey, environmentKey, params, batch }),
);
