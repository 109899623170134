import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { HoldoutPatchParams } from './types';

export async function patchHoldout({
  projectKey,
  environmentKey,
  holdoutKey,
  input,
}: {
  projectKey: string;
  environmentKey: string;
  holdoutKey: string;
  input: HoldoutPatchParams;
}) {
  return reactQueryResponseAdapter(
    getClient().PATCH('/api/v2/projects/{projectKey}/environments/{environmentKey}/holdouts/{holdoutKey}', {
      headers: {
        'LD-API-Version': 'beta',
        'Content-Type': 'application/json; domain-model=launchdarkly.semanticpatch',
      },
      params: {
        path: { projectKey, environmentKey, holdoutKey },
      },
      body: input,
    }),
  );
}
