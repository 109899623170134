import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { patchMemberQuickstartStatus } from './internal/patchMemberQuickstartStatus';
import { members } from './internal/queries';

export const useUpdateMemberQuickstartStatus = createMutationHook({
  mutationFn: patchMemberQuickstartStatus,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: members.profile.queryKey });
  },
});
