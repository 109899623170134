import { createQueryHook } from './internal/createQueryHook';
import { experiments } from './internal/queries';
import { GetExperimentsParams } from './internal/types';

export const useExperiments = createQueryHook(
  ({
    projectKey,
    environmentKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    params?: GetExperimentsParams;
  }) => experiments.list({ projectKey, environmentKey, params }),
);
