import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { serializeProjectFilterParam } from './serializeProjectListFilterParam';
import { GetProjectsParams } from './types';

export async function getProjects({ params }: { params?: GetProjectsParams }) {
  return reactQueryResponseAdapter(
    getClient().GET('/api/v2/projects', {
      headers: { 'Ld-Api-Version': '20220603' },
      // @ts-expect-error Our OpenAPI spec generator doesn't support the `explode` parameter
      // serialization method yet
      params: { query: params },
      // @ts-expect-error we hardcode the type here to work around the limitation above
      querySerializer: (queryParams: GetProjectsParams) => {
        const searchParams = new URLSearchParams();

        if ((queryParams.limit ?? 0) > 0) {
          searchParams.set('limit', String(queryParams.limit));
        }

        if ((queryParams.offset ?? 0) > 0) {
          searchParams.set('offset', String(queryParams.offset));
        }

        if (queryParams.sort) {
          searchParams.set('sort', queryParams.sort);
        }

        if (queryParams.expand && queryParams.expand.length > 0) {
          searchParams.set('expand', queryParams.expand.join(','));
        }

        if (queryParams.filter) {
          const serializedFilter = serializeProjectFilterParam(queryParams.filter);
          if (serializedFilter) {
            searchParams.set('filter', serializedFilter);
          }
        }

        return searchParams.toString();
      },
    }),
  );
}
