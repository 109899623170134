import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export async function putRelease({
  projectKey,
  flagKey,
  input,
}: {
  projectKey: string;
  flagKey: string;
  input: {
    releasePipelineKey: string;
    releaseVariationId?: string;
  };
}) {
  return reactQueryResponseAdapter(
    getClient().PUT('/api/v2/projects/{projectKey}/flags/{flagKey}/release', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey, flagKey },
      },
      body: input,
    }),
  );
}
