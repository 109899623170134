export type PayloadFilter = {
  query?: string;
  archived?: boolean;
};

export function serializePayloadFilterParam(value: PayloadFilter) {
  const filter: string[] = [];

  if (value.query) {
    filter.push(`query:${value.query}`);
  }

  if (value.archived) {
    filter.push(`archived:${String(value.archived)}`);
  }

  return filter.sort().join(',');
}
