import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { postSSODomain } from './internal/postSSODomain';
import { ssoDomains } from './internal/queries';

export const useCreateSSODomain = createMutationHook({
  mutationFn: postSSODomain,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: ssoDomains._def });
  },
});
