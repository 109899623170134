import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { patchFollowPreferences } from './internal/patchFollowPreferences';
import { followPreferences } from './internal/queries';

export const useUpdateFollowPreferences = createMutationHook({
  mutationKey: followPreferences.detail.queryKey,
  mutationFn: patchFollowPreferences,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: followPreferences.detail.queryKey });
  },
});
