import { createSuspenseQueryHook } from './internal/createSuspenseQueryHook';
import { flags } from './internal/queries';
import { FlagEvaluationParams } from './internal/types';

export const useSuspenseFlagEvaluationsForEnvironment = createSuspenseQueryHook(
  ({
    projectKey,
    flagKey,
    environmentKey,
    params,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    params?: FlagEvaluationParams;
  }) => flags.detail({ projectKey, flagKey })._ctx.evaluations({ environmentKey, flagEvaluationParams: params }),
);
