import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { deleteSSODomain } from './internal/deleteSSODomain';
import { ssoDomains } from './internal/queries';

export const useDeleteSSODomain = createMutationHook({
  mutationFn: deleteSSODomain,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: ssoDomains._def });
  },
});
