import { createTaskRunner } from '@gonfalon/async';
import { getClient, paths, schemas } from '@gonfalon/openapi';

import { getMeasuredRolloutStatusBatcher, MeasuredRolloutStatusTask } from './getMeasuredRolloutStatusesTaskBatcher';
import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

type MeasuredRolloutStatusesParams = NonNullable<
  paths['/internal/projects/{projectKey}/environments/{environmentKey}/measured-rollouts/status']['post']['requestBody']['content']['application/json']
>;

export async function getMeasuredRolloutStatusesPerEnvironment({
  projectKey,
  environmentKey,
  query,
}: {
  projectKey: string;
  environmentKey: string;
  query: MeasuredRolloutStatusesParams;
}) {
  return reactQueryResponseAdapter(
    getClient().POST('/internal/projects/{projectKey}/environments/{environmentKey}/measured-rollouts/status', {
      headers: {
        'Content-Type': 'application/json',
        'LD-API-Version': 'beta',
      },
      params: {
        path: { projectKey, environmentKey },
      },
      body: query,
    }),
  );
}

type SchemaWithEnvironment = schemas['FlagMeasuredRolloutStatus'] & {
  environmentKey: string;
};

type GetMeasuredRolloutStatusesPerEnvironmentWithEnvironment = {
  items: Array<{
    environmentKey: string;
    flagKey: string;
    measuredRolloutStatus: schemas['FlagMeasuredRolloutStatus']['measuredRolloutStatus'];
  }>;
};

export const measuredRolloutStatusBatcher = createTaskRunner<
  MeasuredRolloutStatusTask,
  SchemaWithEnvironment[],
  GetMeasuredRolloutStatusesPerEnvironmentWithEnvironment
>({
  runner: async (tasks) => {
    const environmentKeys = Array.from(new Set(tasks.map((input) => input.environmentKey)));
    const results = await Promise.all(
      environmentKeys.map(async (environmentKey) => {
        const response = await getMeasuredRolloutStatusesPerEnvironment({
          projectKey: tasks[0].projectKey,
          environmentKey,
          query: {
            flagKeys: Array.from(new Set(tasks.flatMap((input) => input.flagKey))),
          },
        });
        return {
          items: [
            ...response.items.map((item) => {
              return {
                ...item,
                environmentKey,
              };
            }),
          ],
        };
      }),
    );

    return {
      items: results.flatMap((result) => result.items),
    };
  },
  resolver: (input, task) => {
    const { items } = input;
    const results = items.find((item) => item.flagKey === task.flagKey && item.environmentKey === task.environmentKey);

    if (!results) {
      return [];
    }

    return [
      {
        flagKey: task.flagKey,
        measuredRolloutStatus: results.measuredRolloutStatus,
        environmentKey: results.environmentKey,
      },
    ];
  },
  batching: {
    batcher: getMeasuredRolloutStatusBatcher,
    resultMerger(data) {
      return {
        items: data.flatMap((item) => item.items),
      };
    },
  },
});
