import { getClient, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export type CreateHoldoutInput = schemas['HoldoutPostRequest'];

export async function postHoldout({
  projectKey,
  environmentKey,
  input,
}: {
  projectKey: string;
  environmentKey: string;
  input: CreateHoldoutInput;
}) {
  return reactQueryResponseAdapter(
    getClient().POST('/api/v2/projects/{projectKey}/environments/{environmentKey}/holdouts', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey, environmentKey },
      },
      body: input,
    }),
  );
}
