import { GetFeatureFlagOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export type GetFeatureFlagQueryParams = Omit<GetFeatureFlagOpenAPIQueryParams, 'env' | 'expand'> & {
  env?: string[];
  expand?: Array<'evaluation' | 'migrationSettings'>;
};

export const apiVersion = '20160426';

export function querySerializer(queryParams: GetFeatureFlagQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.env) {
    for (const env of queryParams.env) {
      searchParams.append('env', env);
    }
  }

  if (queryParams.expand && queryParams.expand.length > 0) {
    searchParams.set('expand', queryParams.expand.join(','));
  }

  return searchParams.toString();
}
