import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { deleteApplication } from './internal/deleteApplication';
import { applications } from './internal/queries';

export const useDeleteApplication = createMutationHook({
  mutationFn: deleteApplication,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: applications.list._def });
  },
});
