import { getClient, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export async function putSelectedEnvironments({
  projectKey,
  selectedEnvironments,
}: {
  projectKey: string;
  selectedEnvironments: schemas['SelectedEnvironmentsPayload'];
}) {
  return reactQueryResponseAdapter(
    getClient().PUT('/internal/profile/selected-environments/{projectKey}', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey },
      },
      body: selectedEnvironments,
    }),
  );
}
