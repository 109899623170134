import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { GetExperimentParams } from './types';

export async function getExperiment({
  projectKey,
  environmentKey,
  experimentKey,
  params,
}: {
  projectKey: string;
  environmentKey: string;
  experimentKey: string;
  params?: GetExperimentParams;
}) {
  return reactQueryResponseAdapter(
    getClient().GET('/api/v2/projects/{projectKey}/environments/{environmentKey}/experiments/{experimentKey}', {
      params: {
        path: { projectKey, environmentKey, experimentKey },
        // @ts-expect-error Our OpenAPI spec generator doesn't support the `explode` parameter
        // serialization method yet
        query: params,
      },
      headers: { 'LD-API-Version': 'beta' },
      // @ts-expect-error we hardcode the type here to work around the limitation above
      querySerializer: (queryParams: GetExperimentParams) => {
        const searchParams = new URLSearchParams();

        if (queryParams.expand) {
          searchParams.set('expand', queryParams.expand.join(','));
        }

        return searchParams.toString();
      },
    }),
  );
}
