import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { postHoldout } from './internal/postHoldout';
import { holdouts } from './internal/queries';

export const useCreateHoldout = createMutationHook({
  mutationFn: postHoldout,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: holdouts.list._def });
  },
});
