import { chunk } from '@gonfalon/collections';
import { measuredRolloutStatusFlagKeyLimit } from '@gonfalon/dogfood-flags';

export type MeasuredRolloutStatusTask = {
  projectKey: string;
  flagKey: string;
  environmentKey: string;
};

export function getMeasuredRolloutStatusBatcher(inputs: MeasuredRolloutStatusTask[]) {
  const flagKeyChunks = chunk(
    Array.from(new Set(inputs.map((input) => input.flagKey))),
    measuredRolloutStatusFlagKeyLimit(),
  );

  const environmentKeyChunks = chunk(Array.from(new Set(inputs.map((input) => input.environmentKey).flat())), 1);

  const batches: MeasuredRolloutStatusTask[][] = [];

  environmentKeyChunks.forEach((chunkOfEnvironmentKeys) => {
    flagKeyChunks.forEach((chunkOfFlagKeys) => {
      const tasks: MeasuredRolloutStatusTask[] = [];
      chunkOfEnvironmentKeys.forEach((environmentKey) => {
        chunkOfFlagKeys.forEach((flagKey) => {
          tasks.push({ projectKey: inputs[0].projectKey, flagKey, environmentKey });
        });
      });
      batches.push(tasks);
    });
  });

  return batches;
}
