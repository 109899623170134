import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { postPendingRequests } from './internal/postPendingRequests';
import { pendingRequests } from './internal/queries';

export const usePostPendingRequests = createMutationHook({
  mutationFn: postPendingRequests,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: pendingRequests.list._def });
  },
});
