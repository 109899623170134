import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { postFlag } from './internal/postFlag';
import { flags } from './internal/queries';

export const useCreateFlag = createMutationHook({
  mutationFn: postFlag,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: flags.list._def });
  },
});
