import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { JSONPatch } from './types';

export async function patchFlagTemplates({
  projectKey,
  templateKey,
  input,
}: {
  projectKey: string;
  templateKey: string;
  input: { patch: JSONPatch };
}) {
  return reactQueryResponseAdapter(
    getClient().PATCH('/internal/projects/{projectKey}/flag-templates/{templateKey}', {
      params: {
        path: { projectKey, templateKey },
      },
      // @ts-expect-error Our OpenAPI spec generator doesn't support the JSON patch format yet
      body: input,
    }),
  );
}
