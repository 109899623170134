import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { patchFlagRelease } from './internal/patchFlagRelease';
import { flags } from './internal/queries';

export const useUpdateFlagRelease = createMutationHook({
  mutationFn: patchFlagRelease,
  onSuccess: async (data, { projectKey, flagKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: flags.detail({ projectKey, flagKey })._ctx.release.queryKey });
  },
});
