import { flagsQueryEnvironmentKeyLimit, flagsQueryFlagKeyLimit } from '@gonfalon/dogfood-flags';
// eslint-disable-next-line no-restricted-imports
import { chunk } from 'lodash';

export type FlagsSearchTask = {
  projectKey: string;
  expand?: string;
  flagKeys: string[];
  environmentKeys: string[];
};

export function getFlagsSearchTaskBatcher(inputs: FlagsSearchTask[]) {
  const flagKeysChunks = chunk(
    Array.from(new Set(inputs.flatMap((input) => input.flagKeys))),
    flagsQueryFlagKeyLimit(),
  );

  const environmentKeysChunks = chunk(
    Array.from(new Set(inputs.flatMap((input) => input.environmentKeys))),
    flagsQueryEnvironmentKeyLimit(),
  );

  const batches: FlagsSearchTask[][] = [];

  for (const flagKeys of flagKeysChunks) {
    if (environmentKeysChunks.length === 0) {
      batches.push([{ projectKey: inputs[0].projectKey, expand: inputs[0].expand, flagKeys, environmentKeys: [] }]);
    } else {
      for (const environmentKeys of environmentKeysChunks) {
        batches.push([{ projectKey: inputs[0].projectKey, expand: inputs[0].expand, flagKeys, environmentKeys }]);
      }
    }
  }

  return batches;
}
