import { createQueryHook } from './internal/createQueryHook';
import { experiments } from './internal/queries';
import { GetExperimentPercentileResultsParams } from './internal/types';

export const useExperimentPercentileResults = createQueryHook(
  ({
    projectKey,
    environmentKey,
    experimentKey,
    metricKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    experimentKey: string;
    metricKey: string;
    params: GetExperimentPercentileResultsParams;
  }) => experiments.detail({ projectKey, environmentKey, experimentKey })._ctx.percentileResults({ metricKey, params }),
);
