import { getClient, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export type GenerateVariationsInput = schemas['GenerateVariationsPost'];

export type GenerateVariationsResponse = schemas['AIGeneratedVariations'];

export type GeneratedVariations = NonNullable<GenerateVariationsResponse['variations']>;

async function generateVariations(input: GenerateVariationsInput) {
  const url = '/api/v2/ai/generate-variations';
  return reactQueryResponseAdapter(
    getClient().POST(url, {
      headers: { 'LD-API-Version': 'beta' },
      body: input,
    }),
  );
}

export { generateVariations };
