import { useMemo } from 'react';
import { createRoutesFromChildren, matchRoutes, RouteMatch, useLocation } from 'react-router-dom';
import { AnyRoute } from '@gonfalon/router';

export const generateMatchingPathPattern = (
  matches: RouteMatch[] | ReturnType<typeof matchRoutes>,
  { notFoundPattern, fallbackPattern }: { notFoundPattern?: AnyRoute; fallbackPattern?: AnyRoute },
) => {
  if (!matches || matches.length === 0) {
    return fallbackPattern;
  }

  if (matches.length === 1 && matches[0].route.path === '*') {
    return notFoundPattern;
  }

  let pathPattern = '';
  for (const match of matches) {
    const path = match.route.path;
    if (!path || path === '/') {
      continue;
    }
    pathPattern = pathPattern.concat(path?.startsWith('/') ? path : `/${path}`);
  }

  if (!pathPattern.startsWith('/')) {
    pathPattern = `/${pathPattern}`;
  }

  return pathPattern as AnyRoute;
};

function useMatches(routes: JSX.Element): RouteMatch[] | null {
  const routeConfig = useMemo(() => createRoutesFromChildren(routes), [routes]);
  return matchRoutes(routeConfig, useLocation());
}
export function useMatchingPathPattern(
  routes: JSX.Element,
  { notFoundPattern, fallbackPattern }: { notFoundPattern?: AnyRoute; fallbackPattern?: AnyRoute } = {},
): AnyRoute | undefined {
  const children: JSX.Element = routes.props.children;
  const matches = useMatches(children);
  return generateMatchingPathPattern(matches, { notFoundPattern, fallbackPattern });
}
