import { getClient, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export type PostScheduledChangesForFlagConfigInput = schemas['PostFlagScheduledChangesInput'];

export async function postScheduledChangesForFlagConfig({
  projectKey,
  featureFlagKey,
  environmentKey,
  input,
}: {
  projectKey: string;
  featureFlagKey: string;
  environmentKey: string;
  input: PostScheduledChangesForFlagConfigInput;
}) {
  return reactQueryResponseAdapter(
    getClient().POST(
      '/api/v2/projects/{projectKey}/flags/{featureFlagKey}/environments/{environmentKey}/scheduled-changes',
      {
        headers: { 'Ld-Api-Version': 'beta' },
        params: {
          path: {
            projectKey,
            featureFlagKey,
            environmentKey,
          },
        },
        body: input,
      },
    ),
  );
}
