import { getClient, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export type CreateFlagInput = schemas['FeatureFlagBody'];

export async function postFlag({ projectKey, input }: { projectKey: string; input: CreateFlagInput }) {
  return reactQueryResponseAdapter(
    getClient().POST('/api/v2/flags/{projectKey}', {
      params: {
        path: { projectKey },
      },
      body: input,
    }),
  );
}
