import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { putReleasePipeline } from './internal/putReleasePipeline';
import { releasePipelines } from './internal/queries';

export const usePutUpdateReleasePipeline = createMutationHook({
  mutationFn: putReleasePipeline,
  onSuccess: async (data, { projectKey, releasePipelineKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({
      queryKey: releasePipelines.detail({ projectKey, releasePipelineKey }).queryKey,
    });
  },
});
