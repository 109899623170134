import { createQueryHook } from './internal/createQueryHook';
import { holdouts } from './internal/queries';
import { GetHoldoutParams } from './internal/types';

export const useHoldout = createQueryHook(
  ({
    projectKey,
    environmentKey,
    holdoutKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    holdoutKey: string;
    params?: GetHoldoutParams;
  }) => holdouts.detail({ projectKey, environmentKey, holdoutKey, params }),
);
