import { GetMembersOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export type GetMembersQueryParams = Omit<GetMembersOpenAPIQueryParams, 'filter'> & {
  filter?: {
    query?: string;
    ids?: string[];
  };
};

export function querySerializer(queryParams: GetMembersQueryParams) {
  const searchParams = new URLSearchParams();
  if (queryParams.filter) {
    const serializedFilter = serializeGetMembersFilterParam(queryParams.filter);
    if (serializedFilter) {
      searchParams.set('filter', serializedFilter);
    }
  }

  if (queryParams.limit) {
    searchParams.set('limit', queryParams.limit.toString());
  }

  if (queryParams.offset) {
    searchParams.set('offset', queryParams.offset.toString());
  }

  return searchParams.toString();
}

export function serializeGetMembersFilterParam(value: GetMembersQueryParams['filter']) {
  const filter: string[] = [];

  if (value?.query) {
    filter.push(`query:${value.query}`);
  }

  if (value?.ids?.length) {
    filter.push(`id:${value.ids.join('|')}`);
  }

  filter.sort();

  return filter.join(',');
}
