import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export async function patchSSODomain({ id }: { id: string }) {
  return reactQueryResponseAdapter(
    getClient().PATCH('/internal/account/domain-verifications/{id}', {
      params: {
        path: { id },
      },
      body: [
        {
          op: 'replace',
          path: '/isVerified',
          value: true,
        },
      ],
    }),
  );
}
