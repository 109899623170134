import { settle } from '@gonfalon/async';
import { getQueryClient } from '@gonfalon/react-query-client';

import { patchFlagSemantically } from './internal/patchFlagSemantically';
import { flags } from './internal/queries';

export async function updateFlagSemantically({
  projectKey,
  flagKey,
  environmentKey,
  instructions,
  ignoreConflicts,
  comment,
}: {
  projectKey: string;
  flagKey: string;
  environmentKey?: string;
  instructions: unknown;
  ignoreConflicts: boolean;
  comment?: string;
}) {
  const queryClient = getQueryClient();

  const [result, error] = await settle(async () =>
    patchFlagSemantically({
      projectKey,
      flagKey,
      ignoreConflicts,
      input: {
        comment,
        environmentKey,
        instructions,
      },
    }),
  );

  if (error) {
    throw error;
  }

  await queryClient.invalidateQueries({ queryKey: flags.list._def });
  // TODO: This should be more targeted (to only invalidate queries for this specific flag),
  // but in practice if we're updating a specific flag it should be the only one with active
  // detail queries
  await queryClient.invalidateQueries({ queryKey: flags.detail._def });

  return result;
}
