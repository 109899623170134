import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { FlagMeasuredRolloutConfigPutInput } from './types';

export async function putFlagMeasuredRolloutConfiguration({
  projectKey,
  flagKey,
  body,
}: {
  projectKey: string;
  flagKey: string;
  body: FlagMeasuredRolloutConfigPutInput;
}) {
  return reactQueryResponseAdapter(
    getClient().PUT('/api/v2/projects/{projectKey}/flags/{flagKey}/measured-rollout-configuration', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey, flagKey },
      },
      body,
    }),
  );
}
