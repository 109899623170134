import { chunk } from '@gonfalon/collections';
import { segmentKeyLimit } from '@gonfalon/dogfood-flags';

import { SegmentsTask } from './getSegments';

export function getSegmentsTaskBatcher(inputs: SegmentsTask[]) {
  const taskKeys = Array.from(new Set(inputs.flatMap((input) => input.params?.filter?.keys || [])));
  let batchedTasks: SegmentsTask[][];
  if (taskKeys === undefined || taskKeys.length <= segmentKeyLimit()) {
    batchedTasks = [inputs];
    return batchedTasks;
  }
  batchedTasks = chunk(taskKeys, segmentKeyLimit()).map((keys) => {
    return [
      {
        projectKey: inputs[0].projectKey,
        environmentKey: inputs[0].environmentKey,
        params: {
          filter: {
            ...inputs[0].params?.filter,
            keys,
          },
        },
      },
    ];
  });
  return batchedTasks;
}
