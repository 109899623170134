import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export async function deleteRelease({ projectKey, flagKey }: { projectKey: string; flagKey: string }) {
  return reactQueryResponseAdapter(
    getClient().DELETE('/api/v2/flags/{projectKey}/{flagKey}/release', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey, flagKey },
      },
    }),
  );
}
