import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export async function deleteContextInstance({
  projectKey,
  environmentKey,
  id,
}: {
  projectKey: string;
  environmentKey: string;
  id: string;
}) {
  return reactQueryResponseAdapter(
    getClient().DELETE('/api/v2/projects/{projectKey}/environments/{environmentKey}/context-instances/{id}', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey, environmentKey, id },
      },
    }),
  );
}
