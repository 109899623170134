import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { patchFlagSemantically } from './internal/patchFlagSemantically';
import { flags } from './internal/queries';

export const useUpdateFlagSemantically = createMutationHook({
  mutationFn: patchFlagSemantically,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: flags.list._def });
  },
});
