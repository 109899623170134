import { noop } from '@gonfalon/es6-utils';
import { type DefaultError, useMutation, UseMutationOptions } from '@tanstack/react-query';

export type ConsumerMutationOptions<Data = unknown, Error = DefaultError, Variables = void, Context = unknown> = Pick<
  UseMutationOptions<Data, Error, Variables, Context>,
  'onMutate' | 'onError' | 'onSuccess' | 'onSettled'
>;

export function createMutationHook<Data, Error, Variables, Context>({
  mutationKey,
  mutationFn,
  onMutate,
  onSuccess,
  onError,
  onSettled,
}: UseMutationOptions<Data, Error, Variables, Context>) {
  return ({
    onMutate: onMutateConsumer,
    onSuccess: onSuccessConsumer,
    onError: onErrorConsumer,
    onSettled: onSettledConsumer,
  }: UseMutationOptions<Data, Error, Variables> = {}) =>
    useMutation<Data, Error, Variables, Context>({
      mutationKey,
      mutationFn,
      onMutate: async (variables) => {
        try {
          const result = onMutateConsumer?.(variables);
          if (result instanceof Promise) {
            result.catch(noop);
          }
        } catch (_) {
          // Ignore errors from the consumer
        }

        // We prioritize the internal callback to help encapsulate
        // any optimistic cache management logic (ie. the context).
        return onMutate?.(variables);
      },
      onSuccess: (data, variables, context) => {
        try {
          const result = onSuccessConsumer?.(data, variables, context);
          if (result instanceof Promise) {
            result.catch(noop);
          }
        } catch (_) {
          // Ignore errors from the consumer
        }

        // We prioritize the internal callback to help encapsulate
        // any optimistic cache management logic (ie. the context).
        return onSuccess?.(data, variables, context);
      },
      onError: (error, variables, context) => {
        try {
          const result = onErrorConsumer?.(error, variables, context);
          if (result instanceof Promise) {
            result.catch(noop);
          }
        } catch (_) {
          // Ignore errors from the consumer
        }

        // We prioritize the internal callback to help encapsulate
        // any optimistic cache management logic (ie. the context).
        return onError?.(error, variables, context);
      },
      onSettled: (data, error, variables, context) => {
        try {
          const result = onSettledConsumer?.(data, error, variables, context);
          if (result instanceof Promise) {
            result.catch(noop);
          }
        } catch (_) {
          // Ignore errors from the consumer
        }

        // We prioritize the internal callback to help encapsulate
        // any optimistic cache management logic (ie. the context).
        return onSettled?.(data, error, variables, context);
      },
    });
}
