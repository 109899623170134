import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { deleteApplicationVersion } from './internal/deleteApplicationVersion';
import { applications } from './internal/queries';

export const useDeleteApplicationVersion = createMutationHook({
  mutationFn: deleteApplicationVersion,
  onSuccess: async (data, { applicationKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: applications.detail({ applicationKey })._ctx.versions._def });
  },
});
