import { createQueryHook } from './internal/createQueryHook';
import { migrationMetrics } from './internal/queries';
import { GetQueryMetricsParams } from './internal/types';

export const useMigrationMetricsForCohort = createQueryHook(
  ({
    projectKey,
    flagKey,
    environmentKey,
    ruleId,
    params,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    ruleId: string;
    params: Omit<GetQueryMetricsParams, 'rules'>;
  }) => migrationMetrics.detail({ projectKey, flagKey, environmentKey, ruleId, params }),
);
