import { getClient, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

type CreateApplicationVersionInput = schemas['CreateApplicationVersionInput'];

export async function postApplicationVersion({
  applicationKey,
  input,
}: {
  applicationKey: string;
  input: CreateApplicationVersionInput;
}) {
  return reactQueryResponseAdapter(
    getClient().POST('/api/v2/applications/{applicationKey}/versions', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { applicationKey },
      },
      body: input,
    }),
  );
}
