import { createTaskRunner } from '@gonfalon/async';
import { getClient, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { GetQueryMetricsParams } from './types';

export async function getMigrationMetrics({
  projectKey,
  flagKey,
  environmentKey,
  params,
}: {
  projectKey: string;
  flagKey: string;
  environmentKey: string;
  params?: GetQueryMetricsParams;
}) {
  return reactQueryResponseAdapter(
    getClient().GET('/internal/projects/{projectKey}/flags/{flagKey}/environments/{environmentKey}/migration-metrics', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey, flagKey, environmentKey },
        // @ts-expect-error Our OpenAPI spec generator doesn't support the `explode` parameter
        // serialization method yet
        query: params,
      },
      // @ts-expect-error we hardcode the type here to work around the limitation above
      querySerializer: (queryParams: GetQueryMetricsParams) => {
        const searchParams = new URLSearchParams();

        if (queryParams.from) {
          searchParams.set('from', String(queryParams.from));
        }

        if (queryParams.to) {
          searchParams.set('to', String(queryParams.to));
        }

        if (queryParams.rules && queryParams.rules.length > 0) {
          searchParams.append('rules', queryParams.rules.join(','));
        }

        return searchParams.toString();
      },
    }),
  );
}

type MetricsTask = {
  projectKey: string;
  flagKey: string;
  environmentKey: string;
  ruleId: string;
  params?: Omit<GetQueryMetricsParams, 'rules'>;
};

export const metricsBatcher = createTaskRunner<
  MetricsTask,
  schemas['RuleMetricsRep'] | undefined,
  Awaited<ReturnType<typeof getMigrationMetrics>>
>({
  runner: async (inputs) => {
    return getMigrationMetrics({
      projectKey: inputs[0].projectKey,
      flagKey: inputs[0].flagKey,
      environmentKey: inputs[0].environmentKey,
      params: { ...inputs[0].params, rules: Array.from(new Set(inputs.map((input) => input.ruleId))) },
    });
  },
  resolver: (result, input) => {
    const res = result.rules?.find((rule) => rule.ruleId === input.ruleId);
    return res
      ? res
      : {
          data: [],
          ruleId: input.ruleId,
          summary: {},
        };
  },
  batching: {
    maxBatchSize: 10,
    resultMerger: (result) => {
      let rules: Array<schemas['RuleMetricsRep']> = [];
      for (const batch of result) {
        rules = rules.concat(batch.rules ?? []);
      }

      return {
        overview: result[0].overview,
        rules,
      };
    },
  },
});
