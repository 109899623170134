import { patchProject } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';

export const useUpdateProjectDefaultReleasePipeline = createMutationHook({
  mutationFn: patchProject,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: ['release-pipelines'] });
  },
});
