import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { serializeMeasuredRolloutListFilterParam } from './serializeMeasuredRolloutListFilterParam';
import type { GetMeasuredRolloutsParams } from './types';

export async function getMeasuredRollouts({
  flagKey,
  projectKey,
  params,
}: {
  flagKey: string;
  projectKey: string;
  params?: GetMeasuredRolloutsParams;
}) {
  return reactQueryResponseAdapter(
    getClient().GET('/api/v2/projects/{projectKey}/flags/{flagKey}/measured-rollouts', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { flagKey, projectKey },
        // @ts-expect-error Our OpenAPI spec generator doesn't support the `explode` parameter
        // serialization method yet
        query: {
          ...params,
          expand: [...(params?.expand ?? []), 'events', 'metrics'], // We always want these for now.
        },
      },
      // @ts-expect-error we hardcode the type here to work around the limitation above
      querySerializer: (queryParams: GetExperimentsParams) => {
        const searchParams = new URLSearchParams();

        if (queryParams.filter) {
          searchParams.set('filter', serializeMeasuredRolloutListFilterParam(queryParams.filter));
        }

        if (queryParams.expand) {
          searchParams.set('expand', queryParams.expand.join(','));
        }

        return searchParams.toString();
      },
    }),
  );
}
