import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { JSONPatch } from './types';

export async function patchFlagImportIntegration({
  projectKey,
  integrationKey,
  integrationId,
  input,
}: {
  projectKey: string;
  integrationKey: string;
  integrationId: string;
  input: JSONPatch;
}) {
  return reactQueryResponseAdapter(
    getClient().PATCH('/api/v2/integration-capabilities/flag-import/{projectKey}/{integrationKey}/{integrationId}', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey, integrationKey, integrationId },
      },
      body: input,
    }),
  );
}
