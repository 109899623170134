import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { JSONPatch } from './types';

export async function patchFlagRelease({
  projectKey,
  flagKey,
  input,
}: {
  projectKey: string;
  flagKey: string;
  input: { comment?: string; patch: JSONPatch };
}) {
  return reactQueryResponseAdapter(
    getClient().PATCH('/api/v2/flags/{projectKey}/{flagKey}/release', {
      params: {
        path: { projectKey, flagKey },
      },
      // @ts-expect-error Our OpenAPI spec generator doesn't support the JSON patch format yet
      body: input,
    }),
  );
}
