import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { GetCodeReferenceStatisticsParams } from './types';

export async function getCodeReferenceStatistics({
  projectKey,
  params,
}: {
  projectKey: string;
  params?: GetCodeReferenceStatisticsParams;
}) {
  return reactQueryResponseAdapter(
    getClient().GET('/api/v2/code-refs/statistics/{projectKey}', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey },
        query: params,
      },
      querySerializer: (queryParams: GetCodeReferenceStatisticsParams) => {
        const searchParams = new URLSearchParams();
        if (queryParams.flagKey) {
          searchParams.set('flagKey', queryParams.flagKey);
        }

        return searchParams.toString();
      },
    }),
  );
}
