import { getClient, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export type CreateReleasePipelineInput = schemas['CreateReleasePipelineInput'];

export async function postReleasePipeline({
  projectKey,
  input,
}: {
  projectKey: string;
  input: CreateReleasePipelineInput;
}) {
  return reactQueryResponseAdapter(
    getClient().POST('/api/v2/projects/{projectKey}/release-pipelines', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey },
      },
      body: input,
    }),
  );
}
