import { useEffect } from 'react';
import { isEnterprisePlan, isProfessionalPlan } from '@gonfalon/billing';
import { useAccountContext } from '@gonfalon/context';

import { resetIntercomInstance } from './resetIntercomInstance.ts';
import { IntercomFlowType } from './types';

export const useUpdateIntercomFlow = (): { flowName: IntercomFlowType } => {
  const { subscription } = useAccountContext();
  let intercomFlowName: IntercomFlowType;
  if (isEnterprisePlan(subscription)) {
    intercomFlowName = 'enterprise';
  } else if (isProfessionalPlan(subscription)) {
    intercomFlowName = 'pro';
  } else {
    intercomFlowName = 'self-serve';
  }
  useEffect(() => {
    resetIntercomInstance({ flowName: intercomFlowName });
  }, [intercomFlowName, subscription]);

  return { flowName: intercomFlowName };
};
