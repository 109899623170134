import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { patchFlagTemplates } from './internal/patchFlagTemplates';
import { internalFlagTemplates } from './internal/queries';

export const useUpdateInternalFlagTemplates = createMutationHook({
  mutationFn: patchFlagTemplates,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: internalFlagTemplates.list._def });
  },
});
