import { isExpiredTrialOrCanceled as isLegacyExpiredTrialOrCanceled } from '../internal/legacySubscription';
import { isExpiredTrialOrCanceled as isModernExpiredTrialOrCanceled } from '../internal/modernSubscription';
import { Subscription } from '../types.ts';

import { isModernSubscription } from './isModernSubscription';

export function isExpiredTrialOrCanceled(subscription: Subscription) {
  if (isModernSubscription(subscription)) {
    return isModernExpiredTrialOrCanceled(subscription);
  }
  return isLegacyExpiredTrialOrCanceled(subscription);
}
