import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

// TODO: updating a flag semantically isn't well captured in our OpenAPI spec…
export async function patchFlagSemantically({
  projectKey,
  flagKey,
  input,
  ignoreConflicts,
}: {
  projectKey: string;
  flagKey: string;
  input: { comment?: string; environmentKey?: string; instructions: unknown };
  ignoreConflicts?: boolean;
}) {
  return reactQueryResponseAdapter(
    getClient().PATCH('/api/v2/flags/{projectKey}/{featureFlagKey}', {
      headers: {
        'Content-Type': 'application/json; domain-model=launchdarkly.semanticpatch',
      },
      params: {
        path: { projectKey, featureFlagKey: flagKey },
        query: {
          ignoreConflicts,
        },
      },
      // @ts-expect-error updating a flag semantically isn't well captured in our OpenAPI spec…
      body: input,
    }),
  );
}
