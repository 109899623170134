import { createQueryFetcher } from './internal/createQueryFetcher';
import { holdouts } from './internal/queries';
import { GetHoldoutParams } from './internal/types';

export const fetchHoldout = createQueryFetcher(
  ({
    projectKey,
    environmentKey,
    holdoutKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    holdoutKey: string;
    params: GetHoldoutParams;
  }) => holdouts.detail({ projectKey, environmentKey, holdoutKey, params }),
);
