import { baseUri } from '@gonfalon/constants';
import { endOfDay } from 'date-fns';
import { z } from 'zod';

import { createInfiniteQueryHook } from './internal/createInfiniteQueryHook';
import { getAuditLog } from './internal/getAuditLog';
import { auditLog } from './internal/queries';
import { GetAuditLogParams, Statement } from './internal/types';

const paramSchema = z.object({
  q: z.string().optional(),
  before: z.coerce.number().optional(),
  after: z.coerce.number().optional(),
  limit: z.coerce.number().optional(),
});

export const useInfiniteAuditLog = createInfiniteQueryHook(
  ({ spec, params }: { spec: Statement[]; params?: GetAuditLogParams }) => ({
    queryKey: auditLog.list({ spec, params }).queryKey,
    queryFn: async ({ pageParam }) => getAuditLog({ spec, params: { ...params, ...pageParam } }),
    initialPageParam: {
      after: params?.after?.valueOf(),
      before: params?.before?.valueOf() ?? endOfDay(new Date()).valueOf(),
    } as GetAuditLogParams,
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage._links?.next?.href;

      if (!nextPage) {
        return undefined;
      }

      const nextUrl = new URL(nextPage, baseUri());
      const nextParams = new URLSearchParams(nextUrl.search);
      const nextValues = Object.fromEntries(nextParams.entries());
      const nextPageParams = paramSchema.safeParse(nextValues);

      if (!nextPageParams.success) {
        return undefined;
      }

      return nextPageParams.data;
    },
  }),
);
