import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { PostProductAnalyticsQueryRep } from './types';

export async function postProductAnalyticsQuery(
  {
    projectKey,
    environmentKey,
    query,
  }: { projectKey: string; environmentKey: string; query: PostProductAnalyticsQueryRep },
  { signal }: { signal?: AbortSignal },
) {
  return reactQueryResponseAdapter(
    getClient().POST('/api/v2/projects/{projectKey}/environments/{environmentKey}/metrics/query', {
      headers: { 'LD-API-Version': 'beta' },
      body: query,
      params: {
        path: { projectKey, environmentKey },
      },
      signal,
    }),
  );
}
