import { trackNewApplicationCreated } from '@gonfalon/applications';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { postApplication } from './internal/postApplication';
import { applications } from './internal/queries';

export const useCreateApplication = createMutationHook({
  mutationFn: postApplication,
  onSuccess: async (data) => {
    trackNewApplicationCreated({ name: data.name, key: data.key, autoAdded: data.autoAdded, kind: data.kind });
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: applications.list._def });
  },
});
