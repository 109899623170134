import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { serializeEnvironmentListFilterParam } from './serializeEnvironmentListFilterParam';
import { GetEnvironmentsParams } from './types';

export async function getEnvironments(
  { projectKey, params }: { projectKey: string; params?: GetEnvironmentsParams },
  { signal }: { signal?: AbortSignal } = {},
) {
  return reactQueryResponseAdapter(
    getClient().GET('/api/v2/projects/{projectKey}/environments', {
      params: {
        path: { projectKey },
        // @ts-expect-error Our OpenAPI spec generator doesn't support the `filter` parameter
        // serialization method yet
        query: params,
      },
      // @ts-expect-error we hardcode the type here to work around the limitation above
      querySerializer: (queryParams: GetEnvironmentsParams) => {
        const searchParams = new URLSearchParams();

        if ((queryParams.limit ?? 0) > 0) {
          searchParams.set('limit', String(queryParams.limit));
        }

        if ((queryParams.offset ?? 0) > 0) {
          searchParams.set('offset', String(queryParams.offset));
        }

        if (queryParams.sort) {
          searchParams.set('sort', queryParams.sort);
        }

        if (queryParams.filter) {
          const serializedFilter = serializeEnvironmentListFilterParam(queryParams.filter);
          if (serializedFilter) {
            searchParams.set('filter', serializedFilter);
          }
        }

        return searchParams.toString();
      },
      signal,
    }),
  );
}
