import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { deleteReleasePipeline } from './internal/deleteReleasePipeline';
import { releasePipelines } from './internal/queries';

export const useDeleteReleasePipeline = createMutationHook({
  mutationFn: deleteReleasePipeline,
  onSuccess: async (_, { projectKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: releasePipelines.list({ projectKey }).queryKey });
  },
});
