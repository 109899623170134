import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { UpdateReleasePipelineInput } from './types';

export async function putReleasePipeline({
  projectKey,
  releasePipelineKey,
  input,
}: {
  projectKey: string;
  releasePipelineKey: string;
  input: UpdateReleasePipelineInput;
}) {
  return reactQueryResponseAdapter(
    getClient().PUT('/api/v2/projects/{projectKey}/release-pipelines/{pipelineKey}', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey, pipelineKey: releasePipelineKey },
      },
      body: input,
    }),
  );
}
