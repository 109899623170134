export type ExperimentStatus = 'not_started' | 'running' | 'stopped' | 'started';
export type ExperimentKind = 'experiment' | 'measuredRollout';

export type ExperimentFilter = {
  flagKey?: string;
  metricKey?: string;
  status?: ExperimentStatus;
  query?: string;
  kind?: ExperimentKind;
};

export function serializeExperimentFilterParam(value: ExperimentFilter) {
  const filter: string[] = [];

  if (value.flagKey) {
    filter.push(`flagKey:${value.flagKey}`);
  }

  if (value.metricKey) {
    filter.push(`metricKey:${value.metricKey}`);
  }

  if (value.status) {
    filter.push(`status:${value.status}`);
  }

  if (value.query) {
    filter.push(`query:${value.query}`);
  }

  if (value.kind) {
    filter.push(`kind:${value.kind}`);
  }

  filter.sort();

  return filter.join(',');
}
