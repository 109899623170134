import { chunk } from '@gonfalon/collections';
import { flagStatusesEnvironmentKeyLimit, flagStatusesFlagKeyLimit } from '@gonfalon/dogfood-flags';

export type FlagStatusTask = {
  projectKey: string;
  flagKey: string;
  environmentKeys: string[];
};

export function getFlagStatusesTaskBatcher(inputs: FlagStatusTask[]) {
  const flagKeyChunks = chunk(Array.from(new Set(inputs.map((input) => input.flagKey))), flagStatusesFlagKeyLimit());
  const environmentKeyChunks = chunk(
    Array.from(new Set(inputs.map((input) => input.environmentKeys).flat())),
    flagStatusesEnvironmentKeyLimit(),
  );

  const batches: FlagStatusTask[][] = [];

  environmentKeyChunks.forEach((chunkOfEnvironmentKeys) => {
    flagKeyChunks.forEach((chunkOfFlagKeys) => {
      const tasks: FlagStatusTask[] = [];
      chunkOfFlagKeys.forEach((flagKey) => {
        tasks.push({ projectKey: inputs[0].projectKey, flagKey, environmentKeys: chunkOfEnvironmentKeys });
      });
      batches.push(tasks);
    });
  });

  return batches;
}
