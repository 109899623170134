import { createTaskRunner } from '@gonfalon/async';
import { getClient, schemas } from '@gonfalon/openapi';

import { getSegmentsTaskBatcher } from './getSegmentsTaskBatcher';
import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { serializeSegmentFilterParam } from './serializeSegmentFilterParam';
import { GetSegmentsParams } from './types';

export async function getSegments({
  projectKey,
  environmentKey,
  params,
}: {
  projectKey: string;
  environmentKey: string;
  params?: GetSegmentsParams;
}) {
  return reactQueryResponseAdapter(
    getClient().GET('/api/v2/segments/{projectKey}/{environmentKey}', {
      params: {
        path: { projectKey, environmentKey },
        // @ts-expect-error Our OpenAPI spec generator doesn't support the `expand` parameter
        query: params,
      },
      // @ts-expect-error we hardcode the type here to work around the limitation above
      querySerializer: (queryParams: GetSegmentsParams) => {
        const searchParams = new URLSearchParams();

        if (queryParams.filter) {
          const serializedFilter = serializeSegmentFilterParam(queryParams.filter);
          if (serializedFilter) {
            searchParams.set('filter', serializedFilter);
          }
        }

        if (queryParams.expand) {
          searchParams.set('expand', queryParams.expand);
        }

        if (queryParams.sort) {
          searchParams.set('sort', queryParams.sort);
        }

        if (queryParams.limit) {
          searchParams.set('limit', queryParams.limit.toString());
        }

        if (queryParams.offset) {
          searchParams.set('offset', queryParams.offset.toString());
        }

        return searchParams.toString();
      },
    }),
  );
}

export type SegmentsTask = {
  projectKey: string;
  environmentKey: string;
  params?: GetSegmentsParams;
};

export const segmentsBatcher = createTaskRunner<
  SegmentsTask,
  schemas['UserSegments'],
  Awaited<ReturnType<typeof getSegments>>
>({
  runner: async (inputs) => {
    return getSegments({
      projectKey: inputs[0].projectKey,
      environmentKey: inputs[0].environmentKey,
      params: {
        ...inputs[0].params,
        filter: {
          ...inputs[0].params?.filter,
          keys: Array.from(new Set(inputs.flatMap((input) => input.params?.filter?.keys || []))),
        },
      },
    });
  },
  resolver: (input) => {
    return input;
  },
  batching: {
    batcher: getSegmentsTaskBatcher,
    resultMerger: (result) => {
      const items = result.reduce((acc, batch) => {
        // @ts-expect-error for some reason TS doesn't understand that `items` is always an array
        return acc.concat(batch.items);
      }, []);
      return {
        _links: result[0]?._links,
        totalCount: result[0]?.totalCount,
        items,
      };
    },
  },
});
