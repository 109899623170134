import { GetExperimentsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export const apiVersion = 'beta';

export type GetExperimentsQueryParams = Omit<
  GetExperimentsOpenAPIQueryParams,
  'filter' | 'expand' | 'lifecycleState'
> & {
  expand?: Array<'previousIterations' | 'draftIteration' | 'secondaryMetrics' | 'treatments'>;
  lifecycleState?: Array<'archived' | 'active'>;
  filter?: {
    flagKey?: string;
    metricKey?: string;
    status?: 'not_started' | 'running' | 'stopped';
    query?: string;
  };
};

export function querySerializer(queryParams: GetExperimentsQueryParams) {
  const searchParams = new URLSearchParams();
  if (queryParams.filter) {
    const serializedFilter = serializeExperimentFilterParam(queryParams.filter);
    if (serializedFilter) {
      searchParams.set('filter', serializedFilter);
    }
  }

  if (queryParams.limit) {
    searchParams.set('limit', queryParams.limit.toString());
  }

  if (queryParams.offset) {
    searchParams.set('offset', queryParams.offset.toString());
  }

  if (queryParams.expand) {
    searchParams.set('expand', queryParams.expand.join(','));
  }

  if (queryParams.lifecycleState) {
    searchParams.set('lifecycleState', queryParams.lifecycleState.join(','));
  }

  return searchParams.toString();
}

export function serializeExperimentFilterParam(value: GetExperimentsQueryParams['filter']) {
  const filter: string[] = [];

  if (value?.flagKey) {
    filter.push(`flagKey:${value.flagKey}`);
  }

  if (value?.metricKey) {
    filter.push(`metricKey:${value.metricKey}`);
  }

  if (value?.status) {
    filter.push(`status:${value.status}`);
  }

  if (value?.query) {
    filter.push(`query:${value.query}`);
  }

  filter.sort();

  return filter.join(',');
}
