import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { JSONPatch } from './types';

export async function patchFollowPreferences({ instructions }: { instructions: JSONPatch }) {
  return reactQueryResponseAdapter(
    getClient().PATCH('/internal/profile/following', {
      body: instructions,
    }),
  );
}
