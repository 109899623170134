import { trackApplicationUpdated } from '@gonfalon/applications';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { patchApplication } from './internal/patchApplication';
import { applications } from './internal/queries';

export const useUpdateApplication = createMutationHook({
  mutationFn: patchApplication,
  onSuccess: async (data, { applicationKey }) => {
    trackApplicationUpdated({ key: data.key, kind: data.kind, name: data.name });
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: applications.list._def });
    await queryClient.invalidateQueries({ queryKey: applications.detail({ applicationKey }).queryKey });
  },
});
