import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { postReleasePipeline } from './internal/postReleasePipeline';
import { releasePipelines } from './internal/queries';

export const useCreateReleasePipeline = createMutationHook({
  mutationFn: postReleasePipeline,
  onSuccess: async (_, { projectKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: releasePipelines.list({ projectKey }).queryKey });
  },
});
