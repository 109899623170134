import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { serializePayloadFilterParam } from './serializePayloadFiltersParam';
import { GetPayloadFiltersParams } from './types';

export async function getPayloadFilters({
  projectKey,
  params,
}: {
  projectKey: string;
  params?: GetPayloadFiltersParams;
}) {
  return reactQueryResponseAdapter(
    getClient().GET('/api/v2/projects/{projectKey}/payload-filters', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey },
        // @ts-expect-error Our OpenAPI spec generator doesn't support the `filter` parameter
        // serialization method yet
        query: params,
      },
      // @ts-expect-error we hardcode the type here to work around the limitation above
      querySerializer: (queryParams: GetPayloadFiltersParams) => {
        const searchParams = new URLSearchParams();

        if (queryParams.filter) {
          searchParams.set('filter', serializePayloadFilterParam(queryParams.filter));
        }

        if (queryParams.limit) {
          searchParams.set('limit', queryParams.limit.toString());
        }

        if (queryParams.offset) {
          searchParams.set('offset', queryParams.offset.toString());
        }

        return searchParams.toString();
      },
    }),
  );
}
