import { getClient, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export type CreateApplicationInput = schemas['CreateApplicationInput'];

export async function postApplication({ input }: { input: CreateApplicationInput }) {
  return reactQueryResponseAdapter(
    getClient().POST('/api/v2/applications', {
      headers: { 'LD-API-Version': 'beta' },
      body: input,
    }),
  );
}
