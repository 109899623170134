import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook.ts';
import { patchLayer } from './internal/patchLayer.ts';
import { layers } from './internal/queries.ts';

export const useUpdateLayer = createMutationHook({
  mutationKey: layers.update._def,
  mutationFn: patchLayer,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: layers.list._def });
  },
});
