import { getClient, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

type patchFlagFollowersRequest = schemas['patchFlagFollowersRequest'];

export async function patchFlagFollowers({
  projectKey,
  flagKey,
  environmentKey,
  instructions,
}: {
  projectKey: string;
  flagKey: string;
  environmentKey: string;
  instructions: patchFlagFollowersRequest['instructions'];
}) {
  return reactQueryResponseAdapter(
    getClient().PATCH(
      '/internal/projects/{projectKey}/flags/{featureFlagKey}/environments/{environmentKey}/followers',
      {
        params: {
          path: { projectKey, featureFlagKey: flagKey, environmentKey },
        },
        body: {
          instructions,
        },
      },
    ),
  );
}
