import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export async function deleteEnvironment({
  projectKey,
  environmentKey,
}: {
  projectKey: string;
  environmentKey: string;
}) {
  return reactQueryResponseAdapter(
    getClient().DELETE('/api/v2/projects/{projectKey}/environments/{environmentKey}', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey, environmentKey },
      },
    }),
  );
}
