import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { patchSegment } from './internal/patchSegment';
import { segments } from './internal/queries';

export const useUpdateSegment = createMutationHook({
  mutationFn: patchSegment,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: segments.list._def });
  },
});
