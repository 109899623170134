import { getQueryClient } from '@gonfalon/react-query-client';

import { patchFlag } from './internal/patchFlag';
import { flags } from './internal/queries';
import { JSONPatch } from './internal/types';

export async function updateFlag({
  projectKey,
  flagKey,
  comment,
  patch,
}: {
  projectKey: string;
  flagKey: string;
  comment?: string;
  patch: JSONPatch;
}) {
  const queryClient = getQueryClient();

  try {
    const result = await patchFlag({
      projectKey,
      flagKey,
      input: {
        comment,
        patch,
      },
    });

    await queryClient.invalidateQueries({ queryKey: flags.list._def });

    return result;
  } catch (error) {
    throw error;
  }
}
