import scrollIntoView from 'scroll-into-view-if-needed';
import type { ScrollMode } from 'scroll-into-view-if-needed/typings/types';

// per the docs (https://www.npmjs.com/package/scroll-into-view-if-needed), the default for scrollMode is 'always'
export function scrollIntoViewIfNeeded(
  el: Element,
  block: ScrollLogicalPosition = 'nearest',
  scrollMode: ScrollMode = 'always',
) {
  scrollIntoView(el, { behavior: 'smooth', block, inline: 'nearest', scrollMode });
}
