import { getClient, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export type RetrieveFlagCleanupInput = schemas['flagCleanupInput'];

export async function postFlagCleanupRetrieve({ flag, repository }: RetrieveFlagCleanupInput) {
  return reactQueryResponseAdapter(
    getClient().POST('/internal/flag-cleanup/retrieve', {
      headers: { 'LD-API-Version': 'beta' },
      body: {
        flag,
        repository: {
          owner: repository?.owner || 'launchdarkly',
          name: repository?.name || 'gonfalon',
        },
      },
    }),
  );
}
