import { createQueryFetcher } from './internal/createQueryFetcher';
import { experiments } from './internal/queries';
import { GetExperimentParams } from './internal/types';

export const fetchExperiment = createQueryFetcher(
  ({
    projectKey,
    environmentKey,
    experimentKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    experimentKey: string;
    params?: GetExperimentParams;
  }) => experiments.detail({ projectKey, environmentKey, experimentKey, params }),
);
