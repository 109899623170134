import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export async function deleteFlagImportIntegration({
  projectKey,
  integrationKey,
  integrationId,
}: {
  projectKey: string;
  integrationKey: string;
  integrationId: string;
}) {
  return reactQueryResponseAdapter(
    getClient().DELETE('/api/v2/integration-capabilities/flag-import/{projectKey}/{integrationKey}/{integrationId}', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey, integrationKey, integrationId },
      },
    }),
  );
}
