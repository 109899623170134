import { trackNewApplicationVersionCreated } from '@gonfalon/applications';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { postApplicationVersion } from './internal/postApplicationVersion';
import { applications } from './internal/queries';

export const useCreateApplicationVersion = createMutationHook({
  mutationFn: postApplicationVersion,
  onSuccess: async (data, { applicationKey }) => {
    trackNewApplicationVersionCreated({ key: data.key, name: data.name, supported: data.supported, applicationKey });
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: applications.detail({ applicationKey })._ctx.versions._def });
  },
});
