import { patchProject } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { projectsDetail } from './internal/queries';

export const useUpdateProjectFlagTemplate = createMutationHook({
  mutationFn: patchProject,
  onSuccess: async (data, { projectKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: projectsDetail({ projectKey }).queryKey });
  },
});
