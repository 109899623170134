import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { JSONPatch } from './types';

export async function patchBigSegmentStoreIntegration({
  projectKey,
  environmentKey,
  integrationKey,
  integrationId,
  input,
}: {
  projectKey: string;
  environmentKey: string;
  integrationKey: string;
  integrationId: string;
  input: JSONPatch;
}) {
  return reactQueryResponseAdapter(
    getClient().PATCH(
      '/api/v2/integration-capabilities/big-segment-store/{projectKey}/{environmentKey}/{integrationKey}/{integrationId}',
      {
        headers: { 'LD-API-Version': 'beta' },
        params: {
          path: { projectKey, environmentKey, integrationKey, integrationId },
        },
        body: input,
      },
    ),
  );
}
