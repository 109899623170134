export type ProjectFilter = {
  query?: string;
  tags?: string[];
};

export function serializeProjectFilterParam(value: ProjectFilter) {
  const filter: string[] = [];

  if (value?.query) {
    filter.push(`query:${value.query}`);
  }

  if (value?.tags) {
    filter.push(`tags:${value.tags.map(encodeURIComponent).join('+')}`);
  }

  filter.sort();

  return filter.join(',');
}
