import { useNavigate } from 'react-router-dom';
import { refreshBeastModeExpiration } from '@gonfalon/beastmode';
import {
  acceptedRedirectHosts,
  beastModeProfilePageRevamp,
  enableCheckYourEmailEndpoint,
  enableIntercomChatAI,
  halfwayIA,
  isMFARedirectEnabled,
} from '@gonfalon/dogfood-flags';
import { resetIntercomInstance } from '@gonfalon/intercom-fin-app';
import {
  toAccountSettings,
  toCheckYourEmail,
  toChooseOrganization,
  toChooseOrganizationCheckYourEmail,
  toHref,
  toUnauthenticatedVerifyEmail,
  toVerifyOrganizationEmail,
} from '@gonfalon/navigator';
import { isImmutable } from 'immutable';
import { useSendChooseOrganizationVerificationEmailMutation } from 'queries/authQueries';

import {
  navigateToDashboard,
  redirectToDashboardOrContinue,
  redirectToProfile,
  redirectToSSOProvider,
} from 'actions/navigation';
import { useDispatch } from 'hooks/useDispatch';
import { GetState, GlobalDispatch } from 'reducers';
// eslint-disable-next-line import/no-namespace
import * as AuthAPI from 'sources/AuthAPI';
import {
  resendChooseOrganizationEmailVerification as resendChooseOrganizationEmailVerificationAPI,
  resendUnverifiedMemberEmailVerification as unverifiedMemberAPIResendEmailVerification,
} from 'sources/UnverifiedMemberAPI';
import { ImmutableServerError } from 'utils/httpUtils';
import { LoginInfo, redirectToSSO, SignInExperienceType } from 'utils/loginUtils';
import Logger from 'utils/logUtils';
import { GenerateActionType } from 'utils/reduxUtils';
import { ForgotPassword, ResetPassword } from 'utils/resetPasswordUtils';

import { activateBeastMode } from './beastMode';
import { setLoginEndTimeMetricIfValid, setLoginExperienceType } from './timeToLoginMetric';

const logger = Logger.get('AuthActions');

const loginRequiresMfa = (token: string) =>
  ({
    type: 'auth/LOGIN_WITH_MFA',
    token,
  }) as const;

const clearMFA = () =>
  ({
    type: 'auth/CLEAR_MFA_STATE',
  }) as const;

const loginRequiresPassword = (error?: ImmutableServerError) =>
  ({
    type: 'auth/LOGIN_WITH_PASSWORD',
    error,
  }) as const;

const loginFailed = (error: ImmutableServerError) =>
  ({
    type: 'auth/LOGIN_FAILED',
    error,
  }) as const;

const loginDone = (authenticate: boolean) =>
  ({
    type: 'auth/LOGIN_DONE',
    authenticate,
  }) as const;

const loginAction = (info: LoginInfo) => ({ type: 'auth/LOGIN', info }) as const;

function useRedux() {
  const dispatch = useDispatch();
  const setBeastMode = () => dispatch(activateBeastMode());
  const setTimeToLoginType = (type: SignInExperienceType) => dispatch(setLoginExperienceType(type));
  const setTimeToLoginEndTimeMetric = (time: number) => dispatch(setLoginEndTimeMetricIfValid(time));
  const dispatchLoginRequiresMfa = (token: string) => dispatch(loginRequiresMfa(token));
  const navigate = useNavigate();
  return {
    dispatch,
    dispatchLoginRequiresMfa,
    setBeastMode,
    setTimeToLoginType,
    setTimeToLoginEndTimeMetric,
    navigate,
  };
}

/** Clears the MFA state so that the UI does not get stuck & prompt you to use MFA when switching between accounts. */
export const clearMFAState = () => (dispatch: GlobalDispatch) => {
  dispatch(clearMFA());
};

/**
 * Takes the login info, and determines whether the user needs to enter a password or login with SSO. Redirects
 * to user to their SSO provider or the appropriate login page.
 */
export const useFindLoginPage = () => {
  const { dispatch, dispatchLoginRequiresMfa, setTimeToLoginType, setTimeToLoginEndTimeMetric, navigate } = useRedux();
  const sendEmailVerification = useSendChooseOrganizationVerificationEmailMutation();

  return async (info: LoginInfo, redirectPath?: string) => {
    dispatch(loginAction(info));
    const email = info.get('username');

    return AuthAPI.getLoginPage(email, redirectPath)
      .then(async (res) => {
        // special case for Domain Matching redirects
        if (res?.redirected && res?.url && res.url.includes('join-your-organization')) {
          window.location.assign(res.url);
          return;
        }

        if (res?.redirected && res?.url?.includes('/choose-organization/')) {
          // 303 - Special case for multi-account SSO logins. Redirect to the SSO provider.
          navigate(toChooseOrganization({ email: encodeURIComponent(email) }));
          return;
        } else if (res.status === 200) {
          // Password account member, email associated w/ 1 account.
          dispatch(loginRequiresPassword());
          // it is at this point we call loginV2, but until the backend is stood up, we can't access this
        } else if (res.status === 204) {
          // SSO Member, email associated w/ 1 account.
          const destination = redirectPath || '';
          setTimeToLoginType('SSO');
          setTimeToLoginEndTimeMetric(Date.now());

          return redirectToSSO({
            res,
            dispatch,
            redirectPath: destination,
            redirectToSSOProvider,
            dispatchLoginRequiresMfa,
          });
        }
      })
      .catch((error) => {
        const status = error.get('status');

        // The user cannot access the list of organizations until they verify their email.
        if (status === 403) {
          // Send the email, and then direct the user to the Verify Email page.
          sendEmailVerification
            .mutateAsync(email)
            .then(() => {
              if (enableCheckYourEmailEndpoint()) {
                navigate(toChooseOrganizationCheckYourEmail(), { state: { email } });
              } else {
                navigate(toVerifyOrganizationEmail({ email: encodeURIComponent(email) }));
              }
            })
            .catch((e) => dispatch(loginFailed(e)));
          return; // Redirect & return early to prevent dispatching the loginFailed action which would flash an error message on the screen.
        }

        dispatch(loginFailed(error));
      });
  };
};

export const useBeastMode = () => {
  const { dispatch, navigate, setBeastMode } = useRedux();

  return async (info: LoginInfo, redirectPath: string) =>
    AuthAPI.escalateSession(info).then(
      () => {
        setBeastMode();
        refreshBeastModeExpiration();

        if (!beastModeProfilePageRevamp()) {
          navigate(redirectPath || toAccountSettings());
        }

        dispatch(loginDone(true));
      },
      (error) => {
        const status = error.get('status');
        if (status === 401) {
          dispatch(loginRequiresPassword(error));
        } else {
          dispatch(loginFailed(error));
        }
      },
    );
};

const getIsValidRedirectPath = (redirectPath: string) => {
  let url;
  let redirectPathValid = false;
  try {
    url = new URL(redirectPath);
  } catch (_) {
    try {
      url = new URL(redirectPath, window.location.origin);
    } catch (__) {
      url = undefined;
    }
  }
  if (url) {
    const redirectHosts = acceptedRedirectHosts() || [];
    const accepted = [window.location.host, ...redirectHosts];
    redirectPathValid = accepted.includes(url.host);
  }
  return redirectPathValid;
};

export const useLogin = () => {
  const { dispatch, setBeastMode, setTimeToLoginType, setTimeToLoginEndTimeMetric, navigate } = useRedux();

  return async (info: LoginInfo, redirectPath: string, authenticate: boolean) => {
    dispatch(loginAction(info));

    const loginInfo = authenticate ? AuthAPI.escalateSession : AuthAPI.login;
    const isValidRedirectPath = getIsValidRedirectPath(redirectPath);

    return loginInfo(info).then(
      (res) => {
        // special case for Domain Matching redirects
        if (res?.redirected && res?.url && res.url.includes('join-your-org')) {
          window.location.assign(res.url);
          return;
        }
        // MFA or SSO
        if (res.status === 202) {
          setTimeToLoginType('SSO');
          setTimeToLoginEndTimeMetric(Date.now());

          const location = res.headers && res.headers.get('Location');
          if (location) {
            const destination: string = redirectPath || (authenticate ? toHref(toAccountSettings()) : '');
            // SSO
            redirectToSSOProvider(location, destination);
          } else {
            return res.json().then(
              // MFA
              (r: { token: string }) => dispatch(loginRequiresMfa(r.token)),
            );
          }
          return;
        } else if (authenticate) {
          setBeastMode();
          refreshBeastModeExpiration();

          if (!beastModeProfilePageRevamp()) {
            let redirect = redirectPath;

            if (!redirect) {
              const settings = toAccountSettings();
              redirect = `${settings.pathname}${settings.search}${settings.hash}`;
            }

            window.location.assign(redirect);
          }
        } else if (redirectPath && isValidRedirectPath) {
          window.location.href = redirectPath;
        } else {
          redirectToDashboardOrContinue();
        }

        dispatch(loginDone(authenticate));
      },
      (error) => {
        const status = error.get('status');
        const message = error.get('message');

        // 400 - Indicates that you need to choose an organization because it's a multi-organization user.
        if (status === 400 && message === 'You must choose which account to log into') {
          const email = encodeURIComponent(info.get('username'));
          navigate(toChooseOrganization({ email }));
        } else if (status === 401) {
          dispatch(loginRequiresPassword(error));
        } else {
          dispatch(loginFailed(error));
        }
      },
    );
  };
};

export const useLoginV2 = () => {
  const location = window.location;
  const { dispatch, dispatchLoginRequiresMfa, navigate, setTimeToLoginType, setTimeToLoginEndTimeMetric } = useRedux();
  const sendEmailVerification = useSendChooseOrganizationVerificationEmailMutation();

  return async (info: LoginInfo, redirectPath?: string) => {
    const isValidRedirectPath = redirectPath ? getIsValidRedirectPath(redirectPath) : false;
    const searchParams = new URLSearchParams(location.search);
    const queryString = searchParams.toString();
    const pathWithParams = `${redirectPath}${queryString ? `?${queryString}` : ''}`;
    const email = info.get('username');

    return AuthAPI.loginV2(info, redirectPath)
      .then(async (res) => {
        if (res.status === 201) {
          if (redirectPath && isValidRedirectPath) {
            window.location.assign(pathWithParams);
          } else {
            navigateToDashboard();
          }
          if (enableIntercomChatAI()) {
            resetIntercomInstance({ iaExperience: halfwayIA() });
          }
        } else if (res?.redirected && res?.url.includes('join-your-organization')) {
          window.location.assign(res.url);
        } else if (res?.redirected && res?.url?.includes('/choose-organization/')) {
          const chooseOrganizationPage = `/choose-organization/${encodeURIComponent(email)}`;
          navigate(chooseOrganizationPage);
          return;
        } else if (res?.redirected && res?.url) {
          // For single SSO accounts, redirect to the SSO provider.
          window.location.assign(res.url);
        } else if (res.status === 200 && !res?.redirected) {
          // MFA - Should go to the authentication code input.
          const body = await res.json();
          dispatch(loginRequiresMfa(body.token));
        } else if (res.status === 204) {
          setTimeToLoginType('SSO');
          setTimeToLoginEndTimeMetric(Date.now());

          return redirectToSSO({
            res,
            dispatch,
            redirectPath: pathWithParams || '',
            redirectToSSOProvider,
            dispatchLoginRequiresMfa,
          });
        }
      })
      .catch((error) => {
        if (!isImmutable(error)) {
          logger.error('auth error', error);
          dispatch(loginFailed(error));
          return;
        }

        const status = error.get('status');

        // The user cannot access the list of organizations until they verify their email for multi-account members.
        if (status === 403) {
          // Send the email, and then direct the user to the Verify Email page.
          sendEmailVerification
            .mutateAsync(email)
            .then(() => {
              if (enableCheckYourEmailEndpoint()) {
                navigate(toChooseOrganizationCheckYourEmail(), { state: { email } });
              } else {
                navigate(toVerifyOrganizationEmail({ email: encodeURIComponent(email) }));
              }
            })
            .catch((e) => dispatch(loginFailed(e)));
          return; // Redirect & return early to prevent dispatching the loginFailed action which would flash an error message on the screen.
        } else if (status === 422) {
          if (enableCheckYourEmailEndpoint()) {
            navigate(toCheckYourEmail(), { state: { email } });
          } else {
            navigate(toUnauthenticatedVerifyEmail({ email: encodeURIComponent(email) }));
          }

          return; // Redirect & return early to prevent dispatching the loginFailed action which would flash an error message on the screen.
        }
        dispatch(loginFailed(error as ImmutableServerError));
      });
  };
};

const resetPasswordFailed = (error: ImmutableServerError) =>
  ({
    type: 'auth/RESET_PASSWORD_FAILED',
    error,
  }) as const;

const resetPasswordAction = (reset: ResetPassword) =>
  ({
    type: 'auth/RESET_PASSWORD',
    reset,
  }) as const;

export const resetPassword = (reset: ResetPassword) => async (dispatch: GlobalDispatch) => {
  dispatch(resetPasswordAction(reset));
  return AuthAPI.resetPassword(reset).then(
    (res) => {
      // no MFA
      if (res.status === 204) {
        redirectToDashboardOrContinue();
      } else {
        return res.json().then(
          // MFA
          (response: { token: string }) => dispatch(loginRequiresMfa(response.token)),
        );
      }
    },
    (error) => dispatch(resetPasswordFailed(error)),
  );
};

const verifyMFAFailed = (error: ImmutableServerError) =>
  ({
    type: 'auth/VERIFY_MFA_FAILED',
    error,
  }) as const;

const recoverMFAFailed = (error: ImmutableServerError) =>
  ({
    type: 'auth/RECOVER_MFA_FAILED',
    error,
  }) as const;

const verifyMFAAction = () =>
  ({
    type: 'auth/VERIFY_MFA',
  }) as const;

export const verifyMFA =
  (token: string, code: string, redirectPath?: string, accountID?: string) => async (dispatch: GlobalDispatch) => {
    dispatch(verifyMFAAction());
    return AuthAPI.loginWithMFA(token, code, accountID).then(
      (res) => {
        const isValidRedirectPath = redirectPath ? getIsValidRedirectPath(redirectPath) : false;
        if (redirectPath && isValidRedirectPath && isMFARedirectEnabled()) {
          window.location.assign(redirectPath);
        } else if (res?.redirected && res?.url) {
          window.location.assign(res.url);
        } else {
          redirectToDashboardOrContinue();
        }
      },
      (error) => {
        dispatch(verifyMFAFailed(error));
      },
    );
  };

const recoverMFAAction = () =>
  ({
    type: 'auth/RECOVER_MFA',
  }) as const;

export const recoverMFA = (token: string, code: string, accountID?: string) => async (dispatch: GlobalDispatch) => {
  dispatch(recoverMFAAction());
  return AuthAPI.loginWithMFARecovery(token, code, accountID).then(
    () => {
      redirectToProfile('mfa-reset');
    },
    (error) => {
      dispatch(recoverMFAFailed(error));
    },
  );
};

export const createUseMFARecoveryAction = () =>
  ({
    type: 'auth/USE_MFA_RECOVERY',
  }) as const;

export const forgotPasswordAction = (details: ForgotPassword) =>
  ({
    type: 'auth/FORGOT_PASSWORD',
    details,
  }) as const;

export const forgotPasswordDone = (details: ForgotPassword) =>
  ({
    type: 'auth/FORGOT_PASSWORD_DONE',
    details,
  }) as const;

export const forgotPasswordFailed = (details: ForgotPassword, error: ImmutableServerError) =>
  ({
    type: 'auth/FORGOT_PASSWORD_FAILED',
    details,
    error,
  }) as const;

export const forgotPassword = (details: ForgotPassword) => async (dispatch: GlobalDispatch) => {
  dispatch(forgotPasswordAction(details));
  return AuthAPI.forgotPassword(details)
    .then((res) => {
      switch (res.status) {
        case 204:
        default:
          dispatch(forgotPasswordDone(details));
      }
    })
    .catch((error) => dispatch(forgotPasswordFailed(details, error)));
};

export const editForgotPasswordAction = (field: 'username', details: ForgotPassword) =>
  ({
    type: 'auth/EDIT_FORGOT_PASSWORD',
    details,
    field,
  }) as const;

export const editForgotPassword =
  (field: 'username', value: string) => (dispatch: GlobalDispatch, getState: GetState) => {
    const formState = getState().forgotPasswordForm;
    return dispatch(editForgotPasswordAction(field, formState.modified.set(field, value)));
  };

export const resendEmailVerificationStart = () =>
  ({
    type: 'auth/RESEND_EMAIL_VERIFICATION_START',
  }) as const;

export const resendEmailVerificationDone = (email: string, response: void | Response) =>
  ({
    type: 'auth/RESEND_EMAIL_VERIFICATION_DONE',
    email,
    response,
  }) as const;

export const resendEmailVerificationBlocked = () =>
  ({
    type: 'auth/RESEND_EMAIL_VERIFICATION_BLOCKED',
  }) as const;

export const resendEmailVerificationUnblocked = () =>
  ({
    type: 'auth/RESEND_EMAIL_VERIFICATION_UNBLOCKED',
  }) as const;

export const resendEmailVerificationFailed = (error: ImmutableServerError) =>
  ({
    type: 'auth/RESEND_EMAIL_VERIFICATION_FAILED',
    error,
  }) as const;

export function resendUnverifiedMemberEmailVerification(email: string) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(resendEmailVerificationStart());
    return unverifiedMemberAPIResendEmailVerification(email)
      .then((res) => dispatch(resendEmailVerificationDone(email, res)))
      .catch((error: ImmutableServerError) => dispatch(resendEmailVerificationFailed(error)));
  };
}

export function resendChooseOrganizationEmailVerification(email: string) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(resendEmailVerificationStart());
    return resendChooseOrganizationEmailVerificationAPI(email)
      .then((res) => dispatch(resendEmailVerificationDone(email, res)))
      .catch((error: ImmutableServerError) => dispatch(resendEmailVerificationFailed(error)));
  };
}

const AuthActionCreators = {
  clearMFA,
  loginRequiresMfa,
  loginRequiresPassword,
  loginFailed,
  loginDone,
  resetPasswordAction,
  resetPasswordFailed,
  verifyMFAAction,
  recoverMFAAction,
  verifyMFAFailed,
  recoverMFAFailed,
  createUseMFARecoveryAction,
  forgotPasswordAction,
  forgotPasswordFailed,
  forgotPasswordDone,
  editForgotPasswordAction,
  loginAction,
  resendEmailVerificationStart,
  resendEmailVerificationDone,
  resendEmailVerificationBlocked,
  resendEmailVerificationUnblocked,
  resendEmailVerificationFailed,
};

export type AuthAction = GenerateActionType<typeof AuthActionCreators>;
