import { and, anyOf, equals, exists, QueryFilter, queryfilterToString } from '@gonfalon/queryfilter';

export type SegmentListFilter = {
  query?: string;
  tags?: string[];
  keys?: string[];
  kind?: 'standard' | 'list' | 'synced';
  excludedKeys?: string[];
};

export function serializeSegmentFilterParam(value: SegmentListFilter) {
  const filter: QueryFilter[] = [];

  if (value.query) {
    filter.push(equals('query', value.query));
  }

  if (value.tags?.length) {
    filter.push(anyOf('tags', value.tags));
  }

  if (value.keys?.length) {
    filter.push(anyOf('keys', value.keys));
  }

  if (value.kind) {
    switch (value.kind) {
      case 'standard':
        filter.push(equals('unbounded', false));
        filter.push(exists('external', false));
        break;
      case 'list':
        filter.push(equals('unbounded', true));
        filter.push(exists('external', false));
        break;
      case 'synced':
        filter.push(exists('external', true));
        break;
      default:
    }
  }

  if (value.excludedKeys?.length) {
    filter.push(anyOf('excludedKeys', value.excludedKeys));
  }
  return queryfilterToString(and(filter));
}
