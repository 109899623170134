import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { GetHoldoutParams } from './types';

export async function getHoldout(
  {
    projectKey,
    environmentKey,
    holdoutKey,
    params,
  }: { projectKey: string; environmentKey: string; holdoutKey: string; params?: GetHoldoutParams },
  { signal }: { signal?: AbortSignal } = {},
) {
  return reactQueryResponseAdapter(
    getClient().GET('/api/v2/projects/{projectKey}/environments/{environmentKey}/holdouts/{holdoutKey}', {
      params: {
        path: { projectKey, environmentKey, holdoutKey },
        // @ts-expect-error Our OpenAPI spec generator doesn't support the `explode` parameter
        // serialization method yet
        query: params,
      },
      headers: { 'LD-API-Version': 'beta' },
      signal,
      // @ts-expect-error we hardcode the type here to work around the limitation above
      querySerializer: (queryParams: GetHoldoutParams) => {
        const searchParams = new URLSearchParams();

        if (queryParams.expand) {
          searchParams.set('expand', queryParams.expand.join(','));
        }

        return searchParams.toString();
      },
    }),
  );
}
