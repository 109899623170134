import { GetProjectsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export type GetProjectsQueryParams = Omit<GetProjectsOpenAPIQueryParams, 'filter' | 'sort'> & {
  sort?: 'name' | '-name' | 'createdOn' | '-createdOn';
  filter?: {
    query?: string;
    keys?: string[];
    tags?: string[];
  };
};

export function querySerializer(queryParams: GetProjectsQueryParams) {
  const searchParams = new URLSearchParams();
  if (queryParams.filter) {
    const serializedFilter = serializeProjectFilterParam(queryParams.filter);
    if (serializedFilter) {
      searchParams.set('filter', serializedFilter);
    }
  }

  if (queryParams.limit) {
    searchParams.set('limit', queryParams.limit.toString());
  }

  if (queryParams.offset) {
    searchParams.set('offset', queryParams.offset.toString());
  }

  if (queryParams.sort) {
    searchParams.set('sort', queryParams.sort);
  }

  return searchParams.toString();
}

export function serializeProjectFilterParam(value: GetProjectsQueryParams['filter']) {
  const filter: string[] = [];

  if (value?.query) {
    filter.push(`query:${value.query}`);
  }

  if (value?.tags?.length) {
    filter.push(`tags:${value.tags.join('+')}`);
  }

  if (value?.keys?.length) {
    filter.push(`keys:${value.keys.join('|')}`);
  }

  filter.sort();

  return filter.join(',');
}
