import { getClient, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export type CreateSSODomainInput = schemas['CreateDomainVerificationInput'];

export async function postSSODomain({ input }: { input: CreateSSODomainInput }) {
  return reactQueryResponseAdapter(
    getClient().POST('/internal/account/domain-verifications', {
      body: input,
    }),
  );
}
