import { getClient, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

type BigSegmentStoreRequestBody = schemas['IntegrationDeliveryConfigurationPost'];

export async function postBigSegmentStore({
  projectKey,
  environmentKey,
  integrationKey,
  content,
}: {
  projectKey: string;
  environmentKey: string;
  integrationKey: string;
  content: BigSegmentStoreRequestBody;
}) {
  return reactQueryResponseAdapter(
    getClient().POST(
      '/api/v2/integration-capabilities/big-segment-store/{projectKey}/{environmentKey}/{integrationKey}',
      {
        headers: { 'LD-API-Version': 'beta' },
        params: {
          path: { projectKey, environmentKey, integrationKey },
        },
        body: content,
      },
    ),
  );
}
