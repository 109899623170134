import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { ExperimentPatchInput } from './types';

export async function patchExperiment({
  projectKey,
  environmentKey,
  experimentKey,
  input,
}: {
  projectKey: string;
  environmentKey: string;
  experimentKey: string;
  input: ExperimentPatchInput;
}) {
  return reactQueryResponseAdapter(
    getClient().PATCH('/api/v2/projects/{projectKey}/environments/{environmentKey}/experiments/{experimentKey}', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey, environmentKey, experimentKey },
      },
      body: input,
    }),
  );
}
