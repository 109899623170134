import { getClient, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export type UpdatePhaseStatusInput = schemas['UpdatePhaseStatusInput'];

export async function putReleasePhase({
  projectKey,
  flagKey,
  phaseId,
  input,
}: {
  projectKey: string;
  flagKey: string;
  phaseId: string;
  input: UpdatePhaseStatusInput;
}) {
  return reactQueryResponseAdapter(
    getClient().PUT('/api/v2/projects/{projectKey}/flags/{flagKey}/release/phases/{phaseId}', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey, flagKey, phaseId },
      },
      body: input,
    }),
  );
}
