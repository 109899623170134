import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { postProductAnalyticsQueries } from './internal/postProductAnalyticsQueries';
import { metricAnalytics } from './internal/queries';

export const useCreateProductAnalyticsQueryID = createMutationHook({
  mutationKey: ['createProductAnalyticsQueryID'],
  mutationFn: postProductAnalyticsQueries,
  onSuccess: async ({ analyticsQueryResultId }, { projectKey, environmentKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({
      queryKey: metricAnalytics.queryResult({ projectKey, environmentKey, analyticsQueryResultId }).queryKey,
    });
  },
});
