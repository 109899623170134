import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { FlagCleanupInput } from './types';

export async function postFlagCleanup({ flag, repository }: FlagCleanupInput) {
  return reactQueryResponseAdapter(
    getClient().POST('/internal/flag-cleanup', {
      headers: { 'LD-API-Version': 'beta' },
      body: {
        flag,
        repository: {
          owner: repository?.owner || 'launchdarkly', // default to ld
          name: repository?.name || 'gonfalon', // default to gonfalon
        },
      },
    }),
  );
}
