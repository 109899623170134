import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { PostProductAnalyticsQueryRep } from './types';

export async function postProductAnalyticsQueries({
  projectKey,
  environmentKey,
  query,
}: {
  projectKey: string;
  environmentKey: string;
  query: PostProductAnalyticsQueryRep;
}) {
  return reactQueryResponseAdapter(
    getClient().POST('/api/v2/projects/{projectKey}/environments/{environmentKey}/metrics/queries', {
      headers: { 'LD-API-Version': 'beta' },
      body: query,
      params: {
        path: { projectKey, environmentKey },
      },
    }),
  );
}
