// Reaching into the src here because we need these individual types, not the single exported union Subscription type.
// Shouldn't need to do this anywhere else.
// eslint-disable-next-line import/no-internal-modules
import { LegacySubscription, ModernSubscription } from '@gonfalon/billing/src/types';
import { hasLegacyPlan } from '@gonfalon/constants';
import { accountDetail, accountLegacySubscription, accountSubscription, membersProfile } from '@gonfalon/rest-api';
import { useSuspenseQueries } from '@tanstack/react-query';

import { Account, Member } from './types';

export function useAccountContext(): {
  profile: Member;
  account: Account;
  subscription: ModernSubscription;
  isLegacySubscription: false;
};
export function useAccountContext(): {
  profile: Member;
  account: Account;
  subscription: LegacySubscription;
  isLegacySubscription: true;
};
export function useAccountContext() {
  const accountHasLegacyPlan = hasLegacyPlan();
  const subscriptionQuery = accountHasLegacyPlan ? accountLegacySubscription : accountSubscription;

  const [profile, account, subscriptionRes] = useSuspenseQueries({
    queries: [membersProfile, accountDetail, subscriptionQuery],
  });

  // In order to get proper typing on the limits, we use the types from the billing package
  // and cast the response to the appropriate type.
  const subscription = accountHasLegacyPlan
    ? (subscriptionRes.data as LegacySubscription)
    : (subscriptionRes.data as ModernSubscription);

  return {
    profile: profile.data,
    account: account.data,
    subscription,
    isLegacySubscription: Boolean(accountHasLegacyPlan),
  };
}
