import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export async function deleteSSODomain({ id }: { id: string }) {
  return reactQueryResponseAdapter(
    getClient().DELETE('/internal/account/domain-verifications/{id}', {
      params: {
        path: {
          id,
        },
      },
    }),
  );
}
