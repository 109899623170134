import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { putReleasePhase } from './internal/putReleasePhase';
import { flags } from './internal/queries';

export const useUpdateReleasePhase = createMutationHook({
  mutationFn: putReleasePhase,
  onSuccess: async (data, { projectKey, flagKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: flags.detail({ projectKey, flagKey })._ctx.release.queryKey });
  },
});
