import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export async function deleteProject({ projectKey }: { projectKey: string }) {
  return reactQueryResponseAdapter(
    getClient().DELETE('/api/v2/projects/{projectKey}', {
      params: {
        path: { projectKey },
      },
    }),
  );
}
