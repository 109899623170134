import { ResourceSpecifier } from './ast';

const projectScopedResources: Array<ResourceSpecifier['type']> = [
  'context-kind',
  'release-pipeline',
  'destination',
  'env',
  'experiment',
  'flag',
  'feature',
  'layer',
  'holdout',
  'metric',
  'metric-group',
  'goal',
  'segment',
  'user',
  'payload-filter',
];

export const isProjectScopedResource = (resourceType: ResourceSpecifier['type']) =>
  projectScopedResources.includes(resourceType);
