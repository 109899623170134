import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import type { GetMeasuredRolloutParams } from './types';

export async function getMeasuredRollout({
  flagKey,
  projectKey,
  environmentKey,
  measuredRolloutId,
  params,
}: {
  flagKey: string;
  projectKey: string;
  environmentKey: string;
  measuredRolloutId: string;
  params?: GetMeasuredRolloutParams;
}) {
  return reactQueryResponseAdapter(
    getClient().GET(
      '/api/v2/projects/{projectKey}/flags/{flagKey}/environments/{environmentKey}/measured-rollouts/{measuredRolloutId}',
      {
        headers: { 'LD-API-Version': 'beta' },
        params: {
          path: { flagKey, projectKey, environmentKey, measuredRolloutId },
          query: {
            ...params,
            expand: [...(params?.expand ?? []), 'events', 'metrics'], // We always want these for now.
          },
        },
      },
    ),
  );
}
