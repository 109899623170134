import { getClient, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export type putShortcutOrderingInput = schemas['putShortcutOrderingRequest'];

export async function putShortcutOrdering({
  projectKey,
  body,
}: {
  projectKey: string;
  body: putShortcutOrderingInput;
}) {
  return reactQueryResponseAdapter(
    getClient().PUT('/internal/profile/shortcuts/{projectKey}', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey },
      },
      body,
    }),
  );
}
