import { and, equals, QueryFilter, queryfilterToString } from '@gonfalon/queryfilter';

export type ReleaseProgressionFilter =
  | {
      status?: 'active';
      activePhaseId?: string;
    }
  | { status: 'completed'; activePhaseId?: never };

export function serializeReleaseProgressionFilterParam(value: ReleaseProgressionFilter) {
  const filters: QueryFilter[] = [];

  if (value.status && ['active', 'completed'].includes(value.status)) {
    filters.push(equals('status', value.status));
  }

  if (value.activePhaseId) {
    filters.push(equals('activePhaseId', value.activePhaseId));
  }

  return queryfilterToString(and(filters));
}
