import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { serializeReleaseProgressionFilterParam } from './serializeReleaseProgressionFilterParam';
import { GetReleaseProgressionsParams } from './types';

export async function getReleaseProgressions({
  projectKey,
  releasePipelineKey,
  params,
}: {
  projectKey: string;
  releasePipelineKey: string;
  params?: GetReleaseProgressionsParams;
}) {
  return reactQueryResponseAdapter(
    getClient().GET('/api/v2/projects/{projectKey}/release-pipelines/{pipelineKey}/releases', {
      headers: { 'LD-API-Version': 'beta' },
      // @ts-expect-error Our OpenAPI spec generator doesn't support the `filter` parameter serialization method yet
      params: { path: { projectKey, pipelineKey: releasePipelineKey }, query: params },
      // @ts-expect-error we hardcode the type here to work around the limitation above
      querySerializer(queryParams: GetReleaseProgressionsParams) {
        const searchParams = new URLSearchParams();

        if ((queryParams.limit ?? 0) > 0) {
          searchParams.set('limit', String(queryParams.limit));
        }

        if ((queryParams.offset ?? 0) > 0) {
          searchParams.set('offset', String(queryParams.offset));
        }

        if (queryParams.filter) {
          const serializedFilter = serializeReleaseProgressionFilterParam(queryParams.filter);
          if (serializedFilter) {
            searchParams.set('filter', serializedFilter);
          }
        }

        return searchParams.toString();
      },
    }),
  );
}
