import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export async function deleteApplicationVersion({
  applicationKey,
  versionKey,
}: {
  applicationKey: string;
  versionKey: string;
}) {
  return reactQueryResponseAdapter(
    getClient().DELETE('/api/v2/applications/{applicationKey}/versions/{versionKey}', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { applicationKey, versionKey },
      },
    }),
  );
}
