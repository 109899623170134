import { ResourceSpecifier } from './ast';

const environmentScopedResources: Array<ResourceSpecifier['type']> = [
  'segment',
  'experiment',
  'holdout',
  'user',
  'destination',
  'flag',
  'feature',
];

export const isEnvironmentScopedResource = (resourceType: ResourceSpecifier['type']) =>
  environmentScopedResources.includes(resourceType);
