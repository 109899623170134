import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export async function deleteSegment({
  projectKey,
  environmentKey,
  segmentKey,
}: {
  projectKey: string;
  environmentKey: string;
  segmentKey: string;
}) {
  return reactQueryResponseAdapter(
    getClient().DELETE('/api/v2/segments/{projectKey}/{environmentKey}/{segmentKey}', {
      params: {
        path: { projectKey, environmentKey, segmentKey },
      },
    }),
  );
}
