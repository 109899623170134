import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { GetMetricParams } from './types';

export async function getMetric({
  projectKey,
  metricKey,
  params,
}: {
  projectKey: string;
  metricKey: string;
  params?: GetMetricParams;
}) {
  return reactQueryResponseAdapter(
    getClient().GET('/api/v2/metrics/{projectKey}/{metricKey}', {
      params: {
        path: { projectKey, metricKey },
        // @ts-expect-error Our OpenAPI spec generator doesn't support the `explode` parameter
        // serialization method yet
        query: params,
      },
      // @ts-expect-error we hardcode the type here to work around the limitation above
      querySerializer: (queryParams: GetMetricParams) => {
        const searchParams = new URLSearchParams();

        if (queryParams.expand && queryParams.expand.length > 0) {
          searchParams.set('expand', queryParams.expand.join(','));
        }

        if (queryParams.versionId && queryParams.versionId !== '') {
          searchParams.set('versionId', queryParams.versionId);
        }

        return searchParams.toString();
      },
    }),
  );
}
