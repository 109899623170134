import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { patchSSODomain } from './internal/patchSSODomain';
import { ssoDomains } from './internal/queries';

export const useUpdateSSODomain = createMutationHook({
  mutationFn: patchSSODomain,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: ssoDomains._def });
  },
});
