import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { PostShortcutsParams } from './types';

export async function createShortcut({ input }: { input: PostShortcutsParams }) {
  return reactQueryResponseAdapter(
    getClient().POST('/api/v2/shortcuts', {
      body: input,
      headers: { 'LD-API-Version': 'beta' },
    }),
  );
}
