import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { JSONPatch } from './types';

export async function patchSegment({
  projectKey,
  environmentKey,
  segmentKey,
  input,
}: {
  projectKey: string;
  environmentKey: string;
  segmentKey: string;
  input: { comment?: string; patch: JSONPatch };
}) {
  return reactQueryResponseAdapter(
    getClient().PATCH('/api/v2/segments/{projectKey}/{environmentKey}/{segmentKey}', {
      params: {
        path: { projectKey, environmentKey, segmentKey },
      },
      body: input,
    }),
  );
}
