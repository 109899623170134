import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export async function deleteFlagLink({ projectKey, flagKey, id }: { projectKey: string; flagKey: string; id: string }) {
  return reactQueryResponseAdapter(
    getClient().DELETE('/api/v2/flag-links/projects/{projectKey}/flags/{featureFlagKey}/{id}', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey, featureFlagKey: flagKey, id },
      },
    }),
  );
}
