import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { putSelectedEnvironments } from './internal/putSelectedEnvironments';
import { queries } from './internal/queries';

export const useUpdateSelectedEnvironments = createMutationHook({
  mutationFn: putSelectedEnvironments,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: queries['selected-environments']._def });
  },
});
