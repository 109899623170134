import { isModernEnterprisePlan } from '../internal/modernSubscription/isModernEnterprisePlan';
import { Subscription } from '../types.ts';

import { isModernSubscription } from './isModernSubscription';

export function isEnterprisePlan(subscription: Subscription): boolean {
  if (isModernSubscription(subscription)) {
    return isModernEnterprisePlan(subscription);
  }
  return false;
}
