export type TeamListFilter = {
  query?: string;
  nomembers?: boolean;
};

export function serializeTeamListFilterParam(value: TeamListFilter) {
  const filter: string[] = [];

  if (value.query) {
    filter.push(`query:${value.query}`);
  }

  if (typeof value.nomembers === 'boolean') {
    filter.push(`nomembers:${value.nomembers}`);
  }

  return filter.join(',');
}
