import { getClient, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export type CreateMembersInput = schemas['NewMemberFormListPost'];

export async function postMembers({ input }: { input: CreateMembersInput }) {
  return reactQueryResponseAdapter(
    getClient().POST('/api/v2/members', {
      body: input,
    }),
  );
}
