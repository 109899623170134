import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { type AuditLogEntryListingRepCollection, GetAuditLogParams, Statement } from './types';

export async function getAuditLog({
  spec,
  params,
}: {
  spec: Statement[];
  params?: GetAuditLogParams;
}): Promise<NonNullable<AuditLogEntryListingRepCollection>> {
  return reactQueryResponseAdapter(
    getClient().POST('/api/v2/auditlog', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        query: params,
      },
      body: spec,
      querySerializer: (
        queryParams: GetAuditLogParams & {
          actions?: string[];
        },
      ) => {
        const searchParams = new URLSearchParams();

        if (queryParams.q && queryParams.q.length > 0) {
          searchParams.set('q', queryParams.q);
        }

        if ((queryParams.limit ?? 0) > 0) {
          searchParams.set('limit', String(queryParams.limit));
        }

        if (queryParams.after) {
          searchParams.set('after', String(queryParams.after));
        }

        if (queryParams.before) {
          searchParams.set('before', String(queryParams.before));
        }

        if (queryParams.actions && queryParams.actions.length > 0) {
          searchParams.set('actions', queryParams.actions.join(','));
        }

        return searchParams.toString();
      },
    }),
  );
}
