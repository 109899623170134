import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { deleteProject } from './internal/deleteProject';
import { projects } from './internal/queries';

export const useDeleteProject = createMutationHook({
  mutationFn: deleteProject,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: projects.list._def });
  },
});
