import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { postBigSegmentStore } from './internal/postBigSegmentStore';
import { bigSegmentStore } from './internal/queries';

export const useCreateBigSegmentStore = createMutationHook({
  mutationFn: postBigSegmentStore,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries(bigSegmentStore.list);
  },
});
