import { createQueryHook } from './internal/createQueryHook';
import { releasePipelines } from './internal/queries';
import { GetReleaseProgressionsParams } from './internal/types';

export const useReleaseProgressions = createQueryHook(
  ({
    projectKey,
    releasePipelineKey,
    params,
  }: {
    projectKey: string;
    releasePipelineKey: string;
    params?: GetReleaseProgressionsParams;
  }) => releasePipelines.detail({ projectKey, releasePipelineKey })._ctx.releaseProgressions({ params }),
);
