import { schemas } from '@gonfalon/openapi';

export type MeasuredRolloutListFilter = {
  environmentKey?: string;
  status?: schemas['MeasuredRolloutStatusKind'];
};

export function serializeMeasuredRolloutListFilterParam(value: MeasuredRolloutListFilter) {
  const filter: string[] = [];

  if (value.environmentKey) {
    filter.push(`environmentKey:${value.environmentKey}`);
  }

  if (value.status) {
    filter.push(`status:${value.status}`);
  }

  return filter.sort().join(',');
}
