import { createQueryHook } from './internal/createQueryHook';
import { PostMigrationSafetyInput } from './internal/getMigrationSafetyIssues';
import { migrationSafetyIssues } from './internal/queries';

export const useMigrationSafetyIssues = createQueryHook(
  ({
    projectKey,
    flagKey,
    environmentKey,
    query,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    query: PostMigrationSafetyInput;
  }) => migrationSafetyIssues.list({ projectKey, flagKey, environmentKey, query }),
);
