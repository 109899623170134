import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { PostPendingRequestsInput } from './types';

export async function postPendingRequests({ kind, input }: { kind: string; input: PostPendingRequestsInput }) {
  return reactQueryResponseAdapter(
    getClient().POST('/api/v2/account/pending-requests/{kind}', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: {
          kind,
        },
      },
      body: input,
    }),
  );
}
