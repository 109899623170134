import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { FlagImportIntegrationPost } from './types';

export async function createFlagImportIntegration({
  projectKey,
  integrationKey,
  input,
}: {
  projectKey: string;
  integrationKey: string;
  input: FlagImportIntegrationPost;
}) {
  return reactQueryResponseAdapter(
    getClient().POST('/api/v2/integration-capabilities/flag-import/{projectKey}/{integrationKey}', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey, integrationKey },
      },
      body: { ...input },
    }),
  );
}
