import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { postLayer } from './internal/postLayer';
import { layers } from './internal/queries';

export const useCreateLayer = createMutationHook({
  mutationFn: postLayer,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: layers.list._def });
  },
});
