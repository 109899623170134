// pluralize the word passed in, using the count value to determine if the word should be pluralized
// handles irregular words, e.g. person/people
// if `inclusive` is true, include the count at the beginning of the returned string
const pluralize = (word: string, count?: number, inclusive: boolean = false) => {
  const IRREGULARS: { [word: string]: string } = {
    is: 'are',
    branch: 'branches',
    person: 'people',
    more: 'more',
  };
  const plural = IRREGULARS[word] || `${word.replace(/s$/, '')}s`;
  const singular = IRREGULARS.hasOwnProperty(word) ? word : word.replace(/s$/, '');
  const pluralized = count !== 1 ? plural : singular;

  return (inclusive ? `${count} ` : '') + pluralized;
};

export { pluralize };
