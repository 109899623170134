import { createQueryHook } from './internal/createQueryHook';
import { holdouts } from './internal/queries';
import { GetHoldoutsParams } from './internal/types';

export const useHoldouts = createQueryHook(
  ({
    projectKey,
    environmentKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    params?: GetHoldoutsParams;
  }) => holdouts.list({ projectKey, environmentKey, params }),
);
