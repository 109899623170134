import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { putFlagMeasuredRolloutConfiguration } from './internal/putFlagMeasuredRolloutConfiguration';
import { flags } from './internal/queries';

export const useUpdateFlagMeasuredRolloutConfiguration = createMutationHook({
  mutationFn: putFlagMeasuredRolloutConfiguration,
  onSuccess: async (data, { projectKey, flagKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: flags.measuredRolloutConfiguration(projectKey, flagKey).queryKey });
  },
});
