import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { patchBigSegmentStoreIntegration } from './internal/patchBigSegmentStoreIntegration';
import { bigSegmentStore } from './internal/queries';

export const useUpdateBigSegmentStoreIntegration = createMutationHook({
  mutationFn: patchBigSegmentStoreIntegration,
  onSuccess: async (data, { projectKey, environmentKey, integrationKey, integrationId }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({
      queryKey: bigSegmentStore.detail({
        projectKey,
        environmentKey,
        integrationKey,
        integrationId,
      }).queryKey,
    });
    await queryClient.invalidateQueries(bigSegmentStore.list);
  },
});
