import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export async function putFlagFollower({
  projectKey,
  flagKey,
  environmentKey,
  memberId,
}: {
  projectKey: string;
  flagKey: string;
  environmentKey: string;
  memberId: string;
}) {
  return reactQueryResponseAdapter(
    getClient().PUT(
      '/api/v2/projects/{projectKey}/flags/{featureFlagKey}/environments/{environmentKey}/followers/{memberId}',
      {
        headers: { 'LD-API-Version': 'beta' },
        params: {
          path: { projectKey, featureFlagKey: flagKey, environmentKey, memberId },
        },
      },
    ),
  );
}
