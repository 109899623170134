import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { GetMetricGroupsParams } from './types';

export async function getMetricGroups({ projectKey, params }: { projectKey: string; params?: GetMetricGroupsParams }) {
  return reactQueryResponseAdapter(
    getClient().GET('/api/v2/projects/{projectKey}/metric-groups', {
      params: {
        path: { projectKey },
        // @ts-expect-error Our OpenAPI spec generator doesn't support the `explode` parameter
        // serialization method yet
        query: params,
      },
      headers: { 'LD-API-Version': 'beta' },
      // @ts-expect-error we hardcode the type here to work around the limitation above
      querySerializer: (queryParams: GetMetricGroupsParams) => {
        const searchParams = new URLSearchParams();

        if (queryParams.expand && queryParams.expand.length > 0) {
          searchParams.set('expand', queryParams.expand.join(','));
        }

        return searchParams.toString();
      },
    }),
  );
}
