import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { JSONPatch } from './types';

export async function patchReleasePipeline({
  projectKey,
  releasePipelineKey,
  input,
}: {
  projectKey: string;
  releasePipelineKey: string;
  input: JSONPatch;
}) {
  return reactQueryResponseAdapter(
    getClient().PATCH('/api/v2/projects/{projectKey}/release-pipelines/{pipelineKey}', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey, pipelineKey: releasePipelineKey },
      },
      // @ts-expect-error Our OpenAPI spec generator doesn't support the JSON patch format yet
      body: input,
    }),
  );
}
