import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { postMembers } from './internal/postMembers';
import { members } from './internal/queries';

export const useCreateMembers = createMutationHook({
  mutationFn: postMembers,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: members.list._def });
  },
});
