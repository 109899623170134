import { createTaskRunner } from '@gonfalon/async';
import { queryFeatureFlagStatuses, schemas } from '@gonfalon/openapi';

import { FlagStatusTask, getFlagStatusesTaskBatcher } from './getFlagStatusesTaskBatcher';
import { mergeEnvironmentsByKey } from './mergeEnvironmentsByKey';
import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export const flagStatusBatcher = createTaskRunner<
  FlagStatusTask,
  Record<string, schemas['FeatureFlagStatus']>,
  NonNullable<Awaited<ReturnType<typeof queryFeatureFlagStatuses>>['data']>
>({
  runner: async (inputs) =>
    reactQueryResponseAdapter(
      queryFeatureFlagStatuses({
        projectKey: inputs[0].projectKey,
        body: {
          flagKeys: Array.from(new Set(inputs.map((input) => input.flagKey))),
          environmentKeys: Array.from(new Set(inputs.map((input) => input.environmentKeys).flat())),
        },
      }),
    ),
  resolver: (input, task) => {
    const { items } = input;
    const flag = items.find((item) => item.key === task.flagKey);

    if (!flag) {
      return {};
    }

    const status: Record<string, schemas['FeatureFlagStatus']> = {};
    for (const environmentKey of task.environmentKeys) {
      const value = flag.environments[environmentKey];
      if (!value) {
        continue;
      }
      status[environmentKey] = value;
    }

    return status;
  },
  batching: {
    batcher: getFlagStatusesTaskBatcher,
    resultMerger(data) {
      return {
        _links: data[0]._links,
        items: mergeEnvironmentsByKey(data),
      };
    },
  },
});
