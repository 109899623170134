import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export async function deleteReleasePipeline({ projectKey, pipelineKey }: { projectKey: string; pipelineKey: string }) {
  return reactQueryResponseAdapter(
    getClient().DELETE('/api/v2/projects/{projectKey}/release-pipelines/{pipelineKey}', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey, pipelineKey },
      },
    }),
  );
}
