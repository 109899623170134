import { createQueryHook } from './internal/createQueryHook';
import { monitoringIssuesList } from './internal/queries';
import { GetMonitoringIssuesQueryParams } from './internal/types';

export const useMonitoringIssues = createQueryHook(
  ({
    projectKey,
    search,
    limit,
    offset,
    sortBy = 'last_seen_error',
  }: { projectKey: string } & GetMonitoringIssuesQueryParams) =>
    monitoringIssuesList({ projectKey, query: { search, limit, offset, sortBy } }),
);
