import { createQueryFetcher } from './internal/createQueryFetcher';
import { segments } from './internal/queries';
import { GetSegmentsParams } from './internal/types';

export const fetchSegments = createQueryFetcher(
  ({
    projectKey,
    params,
    environmentKey,
  }: {
    projectKey: string;
    environmentKey: string;
    params?: GetSegmentsParams;
  }) => segments.list({ projectKey, environmentKey, params }),
);
