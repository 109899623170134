import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { serializeTeamListFilterParam } from './serializeTeamListFilterParam';
import { GetTeamsParams } from './types';

export async function getTeams({ params }: { params?: GetTeamsParams }, { signal }: { signal?: AbortSignal } = {}) {
  return reactQueryResponseAdapter(
    getClient().GET('/api/v2/teams', {
      headers: { 'Ld-Api-Version': '20160426' },
      // @ts-expect-error expects "filter" to be string
      params: { query: params },
      signal,
      // @ts-expect-error expects "expand" to be string
      querySerializer(queryParams: GetTeamsParams) {
        const searchParams = new URLSearchParams();

        if (queryParams.limit !== undefined && queryParams.limit > 0) {
          searchParams.set('limit', queryParams.limit.toString());
        }

        if (queryParams.offset !== undefined && queryParams.offset > 0) {
          searchParams.set('offset', queryParams.offset.toString());
        }

        if (queryParams.filter) {
          const serializedFilter = serializeTeamListFilterParam(queryParams.filter);
          if (serializedFilter) {
            searchParams.set('filter', serializedFilter);
          }
        }

        if (queryParams.expand) {
          const unique = Array.from(new Set(queryParams.expand));
          if (unique.length > 0) {
            searchParams.set('expand', unique.join(','));
          }
        }

        return searchParams.toString();
      },
    }),
  );
}
