import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { postSegment } from './internal/postSegment';
import { segments } from './internal/queries';

export const useCreateSegment = createMutationHook({
  mutationFn: postSegment,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: segments.list._def });
  },
});
