import { schemas } from '@gonfalon/openapi';

export type MemberListFilter = {
  query?: string;
  role?: string[];
  team?: string;
  noteam?: boolean;
  lastSeen?: { never: true } | { noData: true } | { before: number };
  accessCheck?: `${schemas['ActionSpecifier']}:${string}`;
};

export function serializeMemberListFilterParam(value: MemberListFilter) {
  const filter: string[] = [];

  if (value.query) {
    filter.push(`query:${value.query}`);
  }

  if (value.role) {
    const unique = Array.from(new Set(value.role));
    if (unique.length > 0) {
      filter.push(`role:${unique.join('|')}`);
    }
  }

  if (value.team) {
    filter.push(`team:${value.team}`);
  }

  if (typeof value.noteam === 'boolean') {
    filter.push(`noteam:${value.noteam}`);
  }

  if (value.lastSeen) {
    filter.push(`lastSeen:${JSON.stringify(value.lastSeen)})`);
  }

  if (value.accessCheck) {
    filter.push(`accessCheck:${value.accessCheck}`);
  }

  return filter.join(',');
}
