import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export async function putFeaturePreviewEnrollment({ featurePreviewKey }: { featurePreviewKey: string }) {
  return reactQueryResponseAdapter(
    getClient().PUT('/internal/profile/feature-previews/{featurePreviewKey}', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { featurePreviewKey },
      },
    }),
  );
}
