import { getClient, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export type PostApprovalRequestForFlagConfigInput = schemas['createFlagConfigApprovalRequestRequest'];

export async function postFlagConfigApprovalRequest({
  projectKey,
  featureFlagKey,
  environmentKey,
  input,
}: {
  projectKey: string;
  featureFlagKey: string;
  environmentKey: string;
  input: PostApprovalRequestForFlagConfigInput;
}) {
  return reactQueryResponseAdapter(
    getClient().POST(
      '/api/v2/projects/{projectKey}/flags/{featureFlagKey}/environments/{environmentKey}/approval-requests',
      {
        headers: { 'LD-API-Version': 'beta' },
        params: {
          path: {
            projectKey,
            featureFlagKey,
            environmentKey,
          },
        },
        body: input,
      },
    ),
  );
}
