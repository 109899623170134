import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { patchHoldout } from './internal/patchHoldout';
import { holdouts } from './internal/queries';

export const useUpdateHoldout = createMutationHook({
  mutationFn: patchHoldout,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: holdouts.detail._def });
    await queryClient.invalidateQueries({ queryKey: holdouts.list._def });
  },
});
