import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { serializeExperimentFilterParam } from './serializeExperimentFilterParam';
import { GetExperimentsParams } from './types';

export async function getExperiments({
  projectKey,
  environmentKey,
  params,
}: {
  projectKey: string;
  environmentKey: string;
  params?: GetExperimentsParams;
}) {
  return reactQueryResponseAdapter(
    getClient().GET('/api/v2/projects/{projectKey}/environments/{environmentKey}/experiments', {
      params: {
        path: { projectKey, environmentKey },
        // @ts-expect-error Our OpenAPI spec generator doesn't support the `explode` parameter
        // serialization method yet
        query: params,
      },
      headers: {
        'LD-API-Version': 'beta',
      },
      // @ts-expect-error we hardcode the type here to work around the limitation above
      querySerializer: (queryParams: GetExperimentsParams) => {
        const searchParams = new URLSearchParams();

        if ((queryParams.limit ?? 0) > 0) {
          searchParams.set('limit', String(queryParams.limit));
        }

        if ((queryParams.offset ?? 0) > 0) {
          searchParams.set('offset', String(queryParams.offset));
        }

        if (queryParams.filter) {
          searchParams.set('filter', serializeExperimentFilterParam(queryParams.filter));
        }

        if (queryParams.expand) {
          searchParams.set('expand', queryParams.expand.join(','));
        }

        if (queryParams.lifecycleState && queryParams.lifecycleState.length > 0) {
          searchParams.set('lifecycleState', queryParams.lifecycleState.join(','));
        }

        return searchParams.toString();
      },
    }),
  );
}
