import { createTaskRunner } from '@gonfalon/async';
import { internalSearchFlags, schemas } from '@gonfalon/openapi';

import { getFlagsSearchTaskBatcher } from './getFlagsSearchTaskBatcher';
import { mergeEnvironmentsByKey } from './mergeEnvironmentsByKey';
import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

type FlagsSearchTask = {
  projectKey: string;
  expand?: string;
  flagKeys: string[];
  environmentKeys: string[];
};

export const flagsSearchBatcher = createTaskRunner<
  FlagsSearchTask,
  Record<string, schemas['FeatureFlag']>,
  NonNullable<Awaited<ReturnType<typeof internalSearchFlags>>['data']>
>({
  async runner(inputs) {
    return reactQueryResponseAdapter(
      internalSearchFlags({
        projectKey: inputs[0].projectKey,
        query: {
          expand: inputs[0].expand,
        },
        body: {
          flagKeys: Array.from(new Set(inputs.flatMap((input) => input.flagKeys))),
          environmentKeys: Array.from(new Set(inputs.flatMap((input) => input.environmentKeys))),
        },
      }),
    );
  },
  resolver(data, input) {
    const result: Record<string, schemas['FeatureFlag']> = {};

    for (const flagKey of input.flagKeys) {
      const flag = data.items.find((item) => item.key === flagKey);

      if (!flag) {
        continue;
      }

      const environments: schemas['FeatureFlag']['environments'] = {};
      for (const environmentKey of input.environmentKeys) {
        const environment = flag.environments[environmentKey];
        if (environment) {
          environments[environmentKey] = environment;
        }
      }

      result[flagKey] = { ...flag, environments };
    }

    return result;
  },
  batching: {
    batcher: getFlagsSearchTaskBatcher,
    resultMerger(data) {
      return {
        _links: data[0]?._links,
        items: mergeEnvironmentsByKey(data),
        totalCount: data.reduce((total, batch) => total + (batch.totalCount ?? 0), 0),
        totalCountWithDifferences: data.reduce((total, batch) => total + (batch.totalCountWithDifferences ?? 0), 0),
      };
    },
  },
});
