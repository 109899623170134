import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { GetExperimentResultsParams } from './types';

export async function getExperimentResults({
  projectKey,
  environmentKey,
  experimentKey,
  metricKey,
  params,
}: {
  projectKey: string;
  environmentKey: string;
  experimentKey: string;
  metricKey: string;
  params?: GetExperimentResultsParams;
}) {
  return reactQueryResponseAdapter(
    getClient().GET(
      '/api/v2/projects/{projectKey}/environments/{environmentKey}/experiments/{experimentKey}/metrics/{metricKey}/results',
      {
        params: {
          path: { projectKey, environmentKey, experimentKey, metricKey },
          query: params,
        },
        headers: { 'LD-API-Version': 'beta' },
        querySerializer: (queryParams: GetExperimentResultsParams) => {
          const searchParams = new URLSearchParams();

          if (queryParams.iterationId) {
            searchParams.set('iterationId', queryParams.iterationId);
          }

          if (typeof queryParams.sumMetrics !== 'undefined') {
            searchParams.set('sumMetrics', String(queryParams.sumMetrics));
          }

          if (typeof queryParams.defaultMissingMetrics !== 'undefined') {
            searchParams.set('defaultMissingMetrics', String(queryParams.defaultMissingMetrics));
          }

          if (queryParams.attributes) {
            searchParams.set('attributes', JSON.stringify(queryParams.attributes));
          }

          return searchParams.toString();
        },
      },
    ),
  );
}
