import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { GetDiscoveredMetricEventsParams } from './types';

export async function getDiscoveredMetricEvents({
  projectKey,
  environmentKey,
  params,
}: {
  projectKey: string;
  environmentKey: string;
  params?: GetDiscoveredMetricEventsParams;
}) {
  return reactQueryResponseAdapter(
    getClient().GET('/api/v2/projects/{projectKey}/environments/{environmentKey}/discovered-metric-events', {
      params: {
        path: { projectKey, environmentKey },
        query: params,
      },
      headers: {
        'LD-API-Version': 'beta',
      },
    }),
  );
}
