import { getQueryClient } from '@gonfalon/react-query-client';

import { createFlagImportIntegration } from './internal/createFlagImportIntegration';
import { createMutationHook } from './internal/createMutationHook';
import { flagImport } from './internal/queries';

export const useCreateFlagImportIntegration = createMutationHook({
  mutationFn: createFlagImportIntegration,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries(flagImport.list);
  },
});
