import { enableGetSegmentsBatching, releaseListAPIMaxLimit } from '@gonfalon/dogfood-flags';
import { Environment } from '@gonfalon/environments';
import { LayerPatchInstruction } from '@gonfalon/instructions/src/types.ts';
import {
  type GetApplicationsQueryParams,
  type GetApplicationVersionsQueryParams,
  type GetApprovalRequestsQueryParams,
  getAccount,
  getAccountsByEmail,
  getAccountSubscription,
  getActions,
  getAllHoldouts,
  getApplication,
  getApplications,
  getApplicationVersionAdoption,
  getApplicationVersions,
  getApplicationVersionSupported,
  getApprovalRequest,
  getApprovalRequests,
  getAuditLogEntry,
  getBigSegmentStoreIntegration,
  getBigSegmentStoreIntegrations,
  getBillingPlans,
  getContextInstances,
  getContextInstanceSegmentsMembershipByEnv,
  getContextKindsByProjectKey,
  getCustomRole,
  getCustomRoles,
  getCustomWorkflow,
  getDependentFlags,
  getDependentFlagsByEnv,
  getDevice,
  getDomainVerifications,
  getEnvironment,
  getEnvironments,
  getEvaluationsUsage,
  getEventActivity,
  getEventActivityByEnvironment,
  getEventActivityByProject,
  getExperimentationSettings,
  getFeatureFlag,
  getFeatureFlags,
  getFeatureFlagStatusAcrossEnvironments,
  getFeatureFlagTemplates,
  getFeatureFlagTemplatesForProject,
  getFeaturePreviewEnrollments,
  getFlagDefaultsByProject,
  getFlagFollowers,
  getFlagImportConfiguration,
  getFlagImportConfigurations,
  getFlagLinks,
  getFlagMeasuredRolloutConfiguration,
  getFollowersByProjEnv,
  getFollowPreferences,
  getHoldoutById,
  getIntegrationManifests,
  getIssue,
  getIssueErrors,
  getIssues,
  getLayers,
  getLimitPlans,
  getMeasuredRolloutMetricTimeSeriesResults,
  getMember,
  getMetricEvents,
  getMetricsAnalyticsQueryResult,
  getPayloadFilter,
  getProductAnalyticsContextsDimensionValues,
  getProductAnalyticsEventsDimensionValues,
  getProductAnalyticsMetricDimensions,
  getProductAnalyticsMetrics,
  getProfileContext,
  getProject,
  getProjects as generatedGetProjects,
  getRandomizationSettings,
  getReleaseByFlagKey,
  getReleasePipelineByKey,
  getSamlEncryptionCertificates,
  getSegment,
  getSelectedEnvironments,
  getShortcutsByProject,
  getSubscription,
  getSubscriptionUsage,
  getTags,
  getTeam,
  getTopSegmentTags,
  internalGetSessionReplaySessionDetails,
  internalGetSessionReplaySessions,
  patchProject,
  schemas,
  searchContextInstances,
  searchContexts,
} from '@gonfalon/openapi';
import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory';

import { createQueryOptions } from './createQueryOptions.ts';
import { getAuditLog } from './getAuditLog';
import { getCodeReferenceStatistics } from './getCodeReferenceStatistics';
import { getDiscoveredMetricEvents } from './getDiscoveredMetricEvents';
import { getEnvironments as getEnvironmentsByProject } from './getEnvironments';
import { getExperiment } from './getExperiment';
import { getExperimentPercentileResults } from './getExperimentPercentileResults';
import { getExperimentResults } from './getExperimentResults';
import { getExperiments } from './getExperiments';
import { getExperimentsForAnyEnv } from './getExperimentsForAnyEnv';
import { flagsSearchBatcher } from './getFlagsSearch';
import { flagStatusBatcher } from './getFlagStatuses';
import { getHoldout } from './getHoldout';
import { getInternalProjectsWithFlagCount } from './getInternalProjectsWithFlagCount.ts';
import { getMeasuredRollout } from './getMeasuredRollout';
import { getMeasuredRolloutResults } from './getMeasuredRolloutResults';
import { getMeasuredRollouts } from './getMeasuredRollouts';
import { measuredRolloutStatusBatcher } from './getMeasuredRolloutStatuses';
import { getMembers } from './getMembers';
import { getMetric } from './getMetric';
import { getMetricGroup } from './getMetricGroup';
import { getMetricGroups } from './getMetricGroups';
import { getMetrics } from './getMetrics';
import { getMigrationMetrics, metricsBatcher } from './getMigrationMetrics';
import { getMigrationSafetyIssues, PostMigrationSafetyInput } from './getMigrationSafetyIssues';
import { getPayloadFilters } from './getPayloadFilters.ts';
import { getPendingRequests } from './getPendingRequests';
import { getProjects } from './getProjects';
import { getReleasePipelines } from './getReleasePipelines';
import { getReleaseProgressions } from './getReleaseProgressions';
import { getSegments, segmentsBatcher } from './getSegments';
import { getTeams } from './getTeams';
import { patchExperiment } from './patchExperiment';
import { patchLayer } from './patchLayer.ts';
import { postFlagCleanup } from './postFlagCleanup';
import { postFlagCleanupRetrieve, RetrieveFlagCleanupInput } from './postFlagCleanupRetrieve';
import { postProductAnalyticsQuery } from './postQueryMetricAnalytics';
import { reactQueryResponseAdapter } from './reactQueryResponseAdapter.ts';
import type {
  ExperimentPatchInput,
  FlagCleanupInput,
  FlagEvaluationParams,
  GetAuditLogParams,
  GetCodeReferenceStatisticsParams,
  GetDiscoveredMetricEventsParams,
  GetEnvironmentsParams,
  GetExperimentParams,
  GetExperimentPercentileResultsParams,
  GetExperimentResultsParams,
  GetExperimentsParams,
  GetFeatureFlagQueryParams,
  GetFeatureFlagsQueryParams,
  GetHoldoutParams,
  GetHoldoutsParams,
  GetLayersParams,
  GetMeasuredRolloutParams,
  GetMeasuredRolloutsParams,
  GetMembersParams,
  GetMetricGroupParams,
  GetMetricGroupsParams,
  GetMetricParams,
  GetMetricsParams,
  GetPayloadFiltersParams,
  GetPendingRequestsParams,
  GetProjectParams,
  GetProjectsParams,
  GetQueryMetricsParams,
  GetReleasePipelinesParams,
  GetReleaseProgressionsParams,
  GetSegmentsParams,
  GetTagsParams,
  GetTeamsParams,
  JSONPatch,
  PostContextsParams,
  PostProductAnalyticsQueryRep,
  Statement,
} from './types';

export const auditLog = createQueryKeys('audit-log', {
  list: ({ spec, params }: { spec: Statement[]; params?: GetAuditLogParams }) => ({
    queryKey: params ? [spec, params] : [spec],
    queryFn: async () => getAuditLog({ spec, params }),
  }),
  detail: ({ id }: { id: string }) => ({
    queryKey: [id],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getAuditLogEntry({ id }, { signal })),
  }),
});

export const account = createQueryKeys('account', {
  detail: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getAccount({}, { signal })),
    contextQueries: {
      subscription: {
        queryKey: null,
        queryFn: async ({ signal }) => reactQueryResponseAdapter(getSubscription({}, { signal })),
      },
      legacySubscription: {
        queryKey: null,
        queryFn: async ({ signal }) => reactQueryResponseAdapter(getAccountSubscription({}, { signal })),
      },
      subscriptionUsage: {
        queryKey: null,
        queryFn: async ({ signal }) => reactQueryResponseAdapter(getSubscriptionUsage({}, { signal })),
      },
    },
  },
});

export const accountDetail = account.detail;
export const accountSubscription = account.detail._ctx.subscription;
export const accountLegacySubscription = account.detail._ctx.legacySubscription;

export const billingPlans = createQueryKeys('billing-plans', {
  list: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getBillingPlans({}, { signal })),
    contextQueries: {
      planLimits: ({ planType }: { planType: string }) => ({
        queryKey: [planType],
        queryFn: async ({ signal }) => reactQueryResponseAdapter(getLimitPlans({ planType }, { signal })),
      }),
    },
  },
});

export const actions = createQueryKeys('actions', {
  list: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getActions({}, { signal })),
  },
});

export const shortcuts = createQueryKeys('shortcuts', {
  list: ({ projectKey }: { projectKey: string }) => ({
    queryKey: [projectKey],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getShortcutsByProject({ projectKey }, { signal })),
  }),
});

export const shortcutsList = shortcuts.list;

export const migrationSafetyIssues = createQueryKeys('migration-safety-issues', {
  list: ({
    projectKey,
    flagKey,
    environmentKey,
    query,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    query: PostMigrationSafetyInput;
  }) => ({
    queryKey: [projectKey, flagKey, environmentKey, query],
    queryFn: async () => getMigrationSafetyIssues({ projectKey, flagKey, environmentKey, query }),
  }),
});

export const migrationMetrics = createQueryKeys('migration-metrics', {
  list: ({
    projectKey,
    flagKey,
    environmentKey,
    params,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    params?: Omit<GetQueryMetricsParams, 'rules'>;
  }) => ({
    queryKey: params ? [projectKey, flagKey, environmentKey, params] : [projectKey, flagKey, environmentKey],
    queryFn: async () => getMigrationMetrics({ projectKey, flagKey, environmentKey, params }),
  }),
  detail: ({
    projectKey,
    flagKey,
    environmentKey,
    ruleId,
    params,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    ruleId: string;
    params?: Omit<GetQueryMetricsParams, 'rules'>;
  }) => ({
    queryKey: params
      ? [projectKey, flagKey, environmentKey, ruleId, params]
      : [projectKey, flagKey, environmentKey, ruleId],
    queryFn: async () => metricsBatcher.run({ projectKey, flagKey, environmentKey, ruleId, params }),
  }),
});

export const cliDeviceCode = createQueryKeys('device-code', {
  detail: ({ deviceCode }: { deviceCode: string }) => ({
    queryKey: [deviceCode],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getDevice({ deviceCode }, { signal })),
  }),
});

export const flags = createQueryKeys('flags', {
  list: ({ projectKey, params }: { projectKey: string; params?: GetFeatureFlagsQueryParams }) => ({
    queryKey: [projectKey, ...(params ? [params] : [])],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(getFeatureFlags({ projectKey, query: params }, { signal })),
  }),
  cleanup: (input: FlagCleanupInput) => ({
    queryKey: [{ ...input }],
    queryFn: async () => postFlagCleanup(input),
  }),
  getCleanup: (input: RetrieveFlagCleanupInput) => ({
    queryKey: [{ ...input }],
    queryFn: async () => postFlagCleanupRetrieve(input),
  }),
  detail: ({
    projectKey,
    flagKey,
    params,
  }: {
    projectKey: string;
    flagKey: string;
    params?: GetFeatureFlagQueryParams;
  }) => ({
    queryKey: params ? [projectKey, flagKey, params] : [projectKey, flagKey],
    queryFn: async () =>
      reactQueryResponseAdapter(getFeatureFlag({ projectKey, featureFlagKey: flagKey, query: params })),
    contextQueries: {
      allStatuses: {
        queryKey: null,
        queryFn: async () =>
          reactQueryResponseAdapter(getFeatureFlagStatusAcrossEnvironments({ projectKey, featureFlagKey: flagKey })),
      },
      evaluations: ({
        environmentKey,
        flagEvaluationParams,
      }: {
        environmentKey: string;
        flagEvaluationParams?: FlagEvaluationParams;
      }) => ({
        queryKey: flagEvaluationParams ? [environmentKey, flagEvaluationParams] : [environmentKey],
        queryFn: async () =>
          reactQueryResponseAdapter(
            getEvaluationsUsage({ projectKey, environmentKey, featureFlagKey: flagKey, query: flagEvaluationParams }),
          ),
      }),
      status: ({ environmentKeys }: { environmentKeys: string[] }) => ({
        queryKey: [environmentKeys],
        queryFn: async () => flagStatusBatcher.run({ projectKey, flagKey, environmentKeys }),
      }),
      followers: ({ environmentKey }: { environmentKey: string }) => ({
        queryKey: [environmentKey],
        queryFn: async () =>
          reactQueryResponseAdapter(getFlagFollowers({ projectKey, featureFlagKey: flagKey, environmentKey })),
      }),
      links: {
        queryKey: null,
        queryFn: async () => reactQueryResponseAdapter(getFlagLinks({ projectKey, featureFlagKey: flagKey })),
      },
      release: {
        queryKey: null,
        queryFn: async () => reactQueryResponseAdapter(getReleaseByFlagKey({ projectKey, flagKey })),
      },
      dependents: {
        queryKey: null,
        queryFn: async () => reactQueryResponseAdapter(getDependentFlags({ projectKey, featureFlagKey: flagKey })),
        contextQueries: {
          environments: ({ environmentKey }: { environmentKey: string }) => ({
            queryKey: [environmentKey],
            queryFn: async () =>
              reactQueryResponseAdapter(
                getDependentFlagsByEnv({ projectKey, featureFlagKey: flagKey, environmentKey }),
              ),
          }),
        },
      },
    },
  }),
  search: ({
    projectKey,
    flagKeys,
    environmentKeys,
  }: {
    projectKey: string;
    flagKeys: string[];
    environmentKeys: string[];
  }) => ({
    queryKey: [projectKey, { flagKeys, environmentKeys }],
    queryFn: async () => flagsSearchBatcher.run({ projectKey, flagKeys, environmentKeys }),
  }),
  measuredRolloutConfiguration: (projectKey: string, flagKey: string) => ({
    queryKey: [{ projectKey, entity: 'measuredRolloutConfiguration', flagKey }],
    queryFn: async () => reactQueryResponseAdapter(getFlagMeasuredRolloutConfiguration({ projectKey, flagKey })),
  }),
});

export const flagsList = flags.list;
export const flagsDetail = flags.detail;
export const flagsDetailRoot = flags.detail._def;

export const flagsDetailLinks = ({
  projectKey,
  flagKey,
  params,
}: {
  projectKey: string;
  flagKey: string;
  params?: GetFeatureFlagQueryParams;
}) => flags.detail({ projectKey, flagKey, params })._ctx.links;

export const flagsDetailStatus = ({
  projectKey,
  flagKey,
  environmentKeys,
  params,
}: {
  projectKey: string;
  flagKey: string;
  environmentKeys: string[];
  params?: GetFeatureFlagQueryParams;
}) => flags.detail({ projectKey, flagKey, params })._ctx.status({ environmentKeys });

export const flagsDetailEvaluations = ({
  projectKey,
  flagKey,
  environmentKey,
  flagEvaluationParams,
}: {
  projectKey: string;
  flagKey: string;
  environmentKey: string;
  flagEvaluationParams?: FlagEvaluationParams;
}) => flags.detail({ projectKey, flagKey })._ctx.evaluations({ environmentKey, flagEvaluationParams });

export const layers = createQueryKeys('layers', {
  list: ({ projectKey, params }: { projectKey: string; params?: GetLayersParams }) => ({
    queryKey: [projectKey, ...(params ? [params] : [])],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getLayers({ projectKey, query: params }, { signal })),
  }),
  update: ({
    projectKey,
    layerKey,
    input,
  }: {
    projectKey: string;
    layerKey: string;
    input: { comment?: string; environmentKey?: string; instructions: LayerPatchInstruction[] };
  }) => ({
    queryKey: [projectKey, layerKey, input.environmentKey],
    queryFn: async () => patchLayer({ projectKey, layerKey, input }),
  }),
});

export const applications = createQueryKeys('applications', {
  list: ({ params }: { params?: GetApplicationsQueryParams }) => ({
    // Working around some limitations of query-key-factory here.
    //
    // [params] | null is not a valid type for queryKey, so we add a
    // placeholder string ('list') to satisfy the types.
    queryKey: params ? ['list', params] : ['list'],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getApplications({ query: params }, { signal })),
  }),
  detail: ({ applicationKey }: { applicationKey: string }) => ({
    queryKey: [applicationKey],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getApplication({ applicationKey }, { signal })),
    contextQueries: {
      versions: ({ params }: { params?: GetApplicationVersionsQueryParams }) => ({
        // Working around some limitations of query-key-factory here.
        //
        // [params] | null is not a valid type for queryKey, so we add a
        // placeholder string ('versions') to satisfy the types.
        queryKey: params ? ['versions', params] : ['versions'],
        queryFn: async () => reactQueryResponseAdapter(getApplicationVersions({ applicationKey, query: params })),
      }),
      versionCount: ({ params }: { params: { supported: boolean } }) => ({
        queryKey: [params],
        queryFn: async () =>
          reactQueryResponseAdapter(getApplicationVersionSupported({ applicationKey, query: params })),
      }),
      versionAdoption: {
        queryKey: null,
        queryFn: async ({ signal }) =>
          reactQueryResponseAdapter(getApplicationVersionAdoption({ applicationKey }, { signal })),
      },
    },
  }),
});

export const customRoles = createQueryKeys('customRoles', {
  list: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getCustomRoles({}, { signal })),
  },
  detail: ({ customRoleKey }: { customRoleKey: string }) => ({
    queryKey: [customRoleKey],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getCustomRole({ customRoleKey }, { signal })),
  }),
});

export const ssoDomains = createQueryKeys('ssoDomains', {
  list: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getDomainVerifications({}, { signal })),
  },
});

export const samlEncryptionCertificate = createQueryKeys('samlEncryptionCertificate', {
  list: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getSamlEncryptionCertificates({}, { signal })),
  },
});

export const pendingRequests = createQueryKeys('pendingRequests', {
  list: ({ kind, params }: { kind: string; params?: GetPendingRequestsParams }) => ({
    queryKey: [kind, params],
    queryFn: async () => getPendingRequests({ kind, params }),
  }),
});

export const segments = createQueryKeys('segments', {
  list: ({
    projectKey,
    environmentKey,
    params,
    batch = true,
  }: {
    projectKey: string;
    environmentKey: string;
    params?: GetSegmentsParams;
    batch?: boolean;
  }) => ({
    queryKey: [projectKey, environmentKey, params],
    // enableGetSegmentsBatching is a feature flag tied to escalation SC-233978
    queryFn: async () =>
      enableGetSegmentsBatching() && batch
        ? segmentsBatcher.run({ projectKey, environmentKey, params })
        : getSegments({ projectKey, environmentKey, params }),
    contextQueries: {
      topTags: () => ({
        queryKey: ['topTags', projectKey, environmentKey],
        queryFn: async ({ signal }) =>
          reactQueryResponseAdapter(getTopSegmentTags({ projectKey, environmentKey }, { signal })),
      }),
    },
  }),
  detail: ({
    projectKey,
    environmentKey,
    segmentKey,
    shouldIgnoreErrors,
  }: {
    projectKey: string;
    environmentKey: string;
    segmentKey: string;
    shouldIgnoreErrors?: boolean;
  }) => ({
    queryKey: [projectKey, environmentKey, segmentKey],
    queryFn: async ({ signal }): Promise<schemas['UserSegment']> => {
      try {
        const result = await reactQueryResponseAdapter(
          getSegment({ projectKey, environmentKey, segmentKey }, { signal }),
        );
        return result;
      } catch (error) {
        if (shouldIgnoreErrors) {
          return new Promise((resolve) => resolve(null as unknown as schemas['UserSegment']));
        }

        throw error;
      }
    },
  }),
});

export const segmentList = segments.list;

export const codeReferenceStatistics = createQueryKeys('code-reference-statistics', {
  list: ({ projectKey, params }: { projectKey: string; params?: GetCodeReferenceStatisticsParams }) => ({
    queryKey: params ? [projectKey, params] : [projectKey],
    queryFn: async () => getCodeReferenceStatistics({ projectKey, params }),
  }),
});

export const projects = createQueryKeys('projects', {
  list: ({ params }: { params?: GetProjectsParams }) => ({
    // Working around some limitations of query-key-factory here.
    //
    // [params] | null is not a valid type for queryKey, so we add a
    // placeholder string ('list') to satisfy the types.
    queryKey: params ? ['list', params] : ['list'],
    queryFn: async () => getProjects({ params }),
  }),
  /**
   * @deprecated please use projectsDetail query instead
   */
  detail: ({ projectKey, params }: { projectKey: string; params?: GetProjectParams }) => ({
    queryKey: params ? [projectKey, params] : [projectKey],
    queryFn: async () =>
      reactQueryResponseAdapter(
        getProject({
          projectKey,
          query: params,
        }),
      ),
    contextQueries: {
      flagDefaults: {
        queryKey: null,
        queryFn: async ({ signal }) => reactQueryResponseAdapter(getFlagDefaultsByProject({ projectKey }, { signal })),
      },
      experimentationSettings: {
        queryKey: null,
        queryFn: async ({ signal }) =>
          reactQueryResponseAdapter(getExperimentationSettings({ projectKey }, { signal })),
      },
      randomizationSettings: {
        queryKey: null,
        queryFn: async ({ signal }) => reactQueryResponseAdapter(getRandomizationSettings({ projectKey }, { signal })),
      },
      followers({ environmentKey }: { environmentKey: string }) {
        return {
          queryKey: [environmentKey],
          queryFn: async ({ signal }) =>
            reactQueryResponseAdapter(getFollowersByProjEnv({ projectKey, environmentKey }, { signal })),
        };
      },
    },
  }),
  update: ({ projectKey, input }: { projectKey: string; input: { patch: JSONPatch } }) => ({
    queryKey: [projectKey],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(patchProject({ projectKey, body: input.patch }, { signal })),
  }),
});

export const projectsList = projects.list;
export const projectsDetail = createQueryOptions(getProject);

export const environments = createQueryKeys('environments', {
  list: ({ projectKey, params }: { projectKey: string; params?: GetEnvironmentsParams }) => ({
    queryKey: params ? [projectKey, params] : [projectKey],
    queryFn: async ({ signal }) => getEnvironmentsByProject({ projectKey, params }, { signal }),
  }),
  detail: ({
    projectKey,
    environmentKey,
    shouldIgnoreErrors,
  }: {
    projectKey: string;
    environmentKey: string;
    shouldIgnoreErrors?: boolean;
  }) => ({
    queryKey: [projectKey, environmentKey],
    queryFn: async ({ signal }): Promise<Environment> => {
      try {
        return reactQueryResponseAdapter(getEnvironment({ projectKey, environmentKey }, { signal }));
      } catch (error) {
        if (shouldIgnoreErrors) {
          return new Promise((resolve) => resolve(null as unknown as Environment));
        }

        throw error;
      }
    },
  }),
});

export const environmentsList = environments.list;
export const environmentsDetail = environments.detail;

export const internalEnvironmentList = createQueryOptions(getEnvironments);

export const selectedEnvironments = createQueryKeys('selected-environments', {
  all: ({ projectKey }: { projectKey: string }) => ({
    queryKey: [projectKey],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getSelectedEnvironments({ projectKey }, { signal })),
  }),
});

export const profileContext = createQueryKeys('profileContext', {
  detail: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getProfileContext({}, { signal })),
  },
});

export const releasePipelines = createQueryKeys('release-pipelines', {
  list: ({ projectKey, params }: { projectKey: string; params?: GetReleasePipelinesParams }) => ({
    queryKey: params ? [projectKey, params] : [projectKey],
    queryFn: async () => getReleasePipelines({ projectKey, params }),
  }),
  detail: ({ projectKey, releasePipelineKey }: { projectKey: string; releasePipelineKey: string }) => ({
    queryKey: [projectKey, releasePipelineKey],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(getReleasePipelineByKey({ projectKey, pipelineKey: releasePipelineKey }, { signal })),
    contextQueries: {
      releaseProgressions: (props: { params?: GetReleaseProgressionsParams } = {}) => {
        const { params } = props;
        return {
          // Working around some limitations of query-key-factory here.
          //
          // [params] | null is not a valid type for queryKey, so we add a
          // placeholder string ('releaseProgressions') to satisfy the types.
          queryKey: params ? ['releaseProgressions', params] : ['releaseProgressions'],
          queryFn: async () => getReleaseProgressions({ projectKey, releasePipelineKey, params }),
          contextQueries: {
            paginate: {
              queryKey: ['paginate'],
              queryFn: async (ctx) => {
                const { limit = releaseListAPIMaxLimit() } = params ?? {};
                const { pageParam: offset = 0 } = ctx as { pageParam?: number };
                return getReleaseProgressions({
                  projectKey,
                  releasePipelineKey,
                  params: { ...(params ?? {}), limit, offset },
                });
              },
            },
          },
        };
      },
    },
  }),
});

export const releasePipelinesRoot = releasePipelines._def;
export const releasePipelinesList = releasePipelines.list;
export const releasePipelinesDetail = releasePipelines.detail;

export const releasePipelinesDetailReleaseProgressions = ({
  projectKey,
  releasePipelineKey,
  params = {},
}: {
  projectKey: string;
  releasePipelineKey: string;
  params?: GetReleaseProgressionsParams;
}) => releasePipelines.detail({ projectKey, releasePipelineKey })._ctx.releaseProgressions({ params });

export const members = createQueryKeys('members', {
  list: ({ params }: { params?: GetMembersParams }) => ({
    queryKey: params ? ['collection', params] : ['collection'],
    queryFn: async ({ signal }) => getMembers({ params }, { signal }),
  }),
  profile: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getMember({ id: 'me' }, { signal })),
  },
  detail: ({ memberId }: { memberId: string }) => ({
    queryKey: [memberId],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getMember({ id: memberId }, { signal })),
  }),
});

export const membersProfile = members.profile;

export const membersList = members.list;
export const membersDetail = members.detail;

export const followPreferences = createQueryKeys('followPreferences', {
  detail: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getFollowPreferences({}, { signal })),
  },
});

export const organizations = createQueryKeys('organizations', {
  list: ({ email }: { email: string }) => ({
    queryKey: [email],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getAccountsByEmail({ email }, { signal })),
  }),
});

export const payloadFilter = createQueryKeys('payloadFilter', {
  list: ({ projectKey, params }: { projectKey: string; params?: GetPayloadFiltersParams }) => ({
    queryKey: params ? [projectKey, params] : [projectKey],
    queryFn: async () => getPayloadFilters({ projectKey, params }),
  }),
  detail: ({ projectKey, payloadFilterKey }: { projectKey: string; payloadFilterKey: string }) => ({
    queryKey: [projectKey, payloadFilterKey],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(getPayloadFilter({ projectKey, payloadFilterKey }, { signal })),
  }),
});

export const metrics = createQueryKeys('metrics', {
  list: ({ projectKey, params }: { projectKey: string; params?: GetMetricsParams }) => ({
    queryKey: params ? [projectKey, params] : [projectKey],
    queryFn: async () => getMetrics({ projectKey, params }),
  }),
  detail: ({ projectKey, metricKey, params }: { projectKey: string; metricKey: string; params?: GetMetricParams }) => ({
    queryKey: params ? [projectKey, metricKey, params] : [projectKey, metricKey],
    queryFn: async () => getMetric({ projectKey, metricKey, params }),
  }),
  events: ({
    projectKey,
    environmentKey,
    metricKey,
    offset,
    limit,
    uptoTime,
  }: {
    projectKey: string;
    environmentKey: string;
    metricKey: string;
    offset?: number;
    limit?: number;
    uptoTime?: number;
  }) => ({
    queryKey: [projectKey, environmentKey, metricKey, offset, limit, uptoTime],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(
        getMetricEvents({ projectKey, environmentKey, metricKey, query: { offset, limit, uptoTime } }, { signal }),
      ),
  }),
});

export const metricsList = metrics.list;
export const metricsDetail = metrics.detail;
export const metricsEvents = metrics.events;

export const metricGroups = createQueryKeys('metric-groups', {
  list: ({ projectKey, params }: { projectKey: string; params?: GetMetricGroupsParams }) => ({
    queryKey: params ? [projectKey, params] : [projectKey],
    queryFn: async () => getMetricGroups({ projectKey, params }),
  }),
  detail: ({
    projectKey,
    metricGroupKey,
    params,
  }: {
    projectKey: string;
    metricGroupKey: string;
    params?: GetMetricGroupParams;
  }) => ({
    queryKey: params ? [projectKey, metricGroupKey, params] : [projectKey, metricGroupKey],
    queryFn: async () => getMetricGroup({ projectKey, metricGroupKey, params }),
  }),
});

export const experiments = createQueryKeys('experiments', {
  anyEnv: ({ projectKey, params }: { projectKey: string; params?: GetExperimentsParams }) => ({
    queryKey: params ? [projectKey, params] : [projectKey],
    queryFn: async () => getExperimentsForAnyEnv({ projectKey, params }),
  }),
  list: ({
    projectKey,
    environmentKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    params?: GetExperimentsParams;
  }) => ({
    queryKey: params ? [projectKey, environmentKey, params] : [projectKey, environmentKey],
    queryFn: async () => getExperiments({ projectKey, environmentKey, params }),
  }),
  update: ({
    projectKey,
    environmentKey,
    experimentKey,
    input,
  }: {
    projectKey: string;
    environmentKey: string;
    experimentKey: string;
    input: ExperimentPatchInput;
  }) => ({
    queryKey: [projectKey, environmentKey, experimentKey],
    queryFn: async () => patchExperiment({ projectKey, environmentKey, experimentKey, input }),
  }),
  detail: ({
    projectKey,
    environmentKey,
    experimentKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    experimentKey: string;
    params?: GetExperimentParams;
  }) => ({
    queryKey: params
      ? [projectKey, environmentKey, experimentKey, params]
      : [projectKey, environmentKey, experimentKey],
    queryFn: async () => getExperiment({ projectKey, environmentKey, experimentKey, params }),
    contextQueries: {
      results: ({ metricKey, params: resultsParams }: { metricKey: string; params?: GetExperimentResultsParams }) => ({
        queryKey: params ? [metricKey, params] : [metricKey],
        queryFn: async () =>
          getExperimentResults({ projectKey, environmentKey, experimentKey, metricKey, params: resultsParams }),
      }),
      percentileResults: ({
        metricKey,
        params: percentileResultsParams,
      }: {
        metricKey: string;
        params?: GetExperimentPercentileResultsParams;
      }) => ({
        queryKey: params ? [metricKey, params] : [metricKey],
        queryFn: async () =>
          getExperimentPercentileResults({
            projectKey,
            environmentKey,
            experimentKey,
            metricKey,
            params: percentileResultsParams,
          }),
      }),
    },
  }),
});

export const experimentsList = experiments.list;

export const holdouts = createQueryKeys('holdouts', {
  list: ({
    projectKey,
    environmentKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    params?: GetHoldoutsParams;
  }) => ({
    queryKey: params ? [projectKey, environmentKey, params] : [projectKey, environmentKey],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(getAllHoldouts({ projectKey, environmentKey, query: params?.query }, { signal })),
  }),
  detail: ({
    projectKey,
    environmentKey,
    holdoutKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    holdoutKey: string;
    params?: GetHoldoutParams;
  }) => ({
    queryKey: [projectKey, environmentKey, holdoutKey, params],
    queryFn: async () => getHoldout({ projectKey, environmentKey, holdoutKey, params }),
  }),
  detailById: ({
    projectKey,
    environmentKey,
    holdoutId,
  }: {
    projectKey: string;
    environmentKey: string;
    holdoutId: string;
  }) => ({
    queryKey: [projectKey, environmentKey, holdoutId],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(getHoldoutById({ projectKey, environmentKey, holdoutId }, { signal })),
  }),
});

export const holdoutsList = holdouts.list;
export const holdoutsListRoot = holdouts.list._def;
export const holdoutsDetailRoot = holdouts.detail._def;
export const holdoutsDetail = holdouts.detail;

export const measuredRollouts = createQueryKeys('measured-rollouts', {
  list: ({
    projectKey,
    flagKey,
    params,
  }: {
    projectKey: string;
    flagKey: string;
    params?: GetMeasuredRolloutsParams;
  }) => ({
    queryKey: params ? [projectKey, flagKey, params] : [projectKey, flagKey],
    queryFn: async () => getMeasuredRollouts({ flagKey, projectKey, params }),
  }),
  detail: ({
    projectKey,
    flagKey,
    environmentKey,
    measuredRolloutId,
    params,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    measuredRolloutId: string;
    params?: GetMeasuredRolloutParams;
  }) => ({
    queryKey: params
      ? [projectKey, flagKey, environmentKey, measuredRolloutId, params]
      : [projectKey, flagKey, environmentKey, measuredRolloutId],
    queryFn: async () => getMeasuredRollout({ flagKey, projectKey, environmentKey, measuredRolloutId, params }),
  }),
  metricResults: ({
    projectKey,
    flagKey,
    environmentKey,
    measuredRolloutId,
    metricKey,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    measuredRolloutId: string;
    metricKey: string;
  }) => ({
    queryKey: [projectKey, flagKey, environmentKey, measuredRolloutId, metricKey],
    queryFn: async () =>
      getMeasuredRolloutResults({ projectKey, flagKey, environmentKey, measuredRolloutId, metricKey }),
  }),
  metricTimeSeriesResults: ({
    projectKey,
    flagKey,
    environmentKey,
    measuredRolloutId,
    metricKey,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    measuredRolloutId: string;
    metricKey: string;
  }) => ({
    queryKey: [projectKey, flagKey, environmentKey, measuredRolloutId, metricKey],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(
        getMeasuredRolloutMetricTimeSeriesResults(
          { projectKey, flagKey, environmentKey, measuredRolloutId, metricKey },
          { signal },
        ),
      ),
  }),
  measuredRolloutStatus: ({
    projectKey,
    environmentKey,
    flagKey,
  }: {
    projectKey: string;
    environmentKey: string;
    flagKey: string;
  }) => ({
    queryKey: [environmentKey, flagKey],
    queryFn: async () => measuredRolloutStatusBatcher.run({ projectKey, flagKey, environmentKey }),
  }),
});

export const measuredRolloutsList = measuredRollouts.list;
export const measuredRolloutsDetail = measuredRollouts.detail;
export const measuredRolloutsMetricResults = measuredRollouts.metricResults;
export const measuredRolloutsMetricTimeSeriesResults = measuredRollouts.metricTimeSeriesResults;
export const measuredRolloutsMeasuredRolloutStatus = measuredRollouts.measuredRolloutStatus;

export const metricAnalytics = createQueryKeys('metric-analytics', {
  series: ({
    projectKey,
    environmentKey,
    query,
  }: {
    projectKey: string;
    environmentKey: string;
    query: PostProductAnalyticsQueryRep;
  }) => ({
    queryKey: [projectKey, environmentKey, query],
    queryFn: async ({ signal }) => postProductAnalyticsQuery({ projectKey, environmentKey, query }, { signal }),
  }),
  queryResult: ({
    projectKey,
    environmentKey,
    analyticsQueryResultId,
  }: {
    projectKey: string;
    environmentKey: string;
    analyticsQueryResultId: string;
  }) => ({
    queryKey: [projectKey, environmentKey, analyticsQueryResultId],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(
        getMetricsAnalyticsQueryResult({ projectKey, environmentKey, analyticsQueryResultId }, { signal }),
      ),
  }),
  metrics: ({
    projectKey,
    environmentKey,
    startsWith,
    limit = 20,
  }: {
    projectKey: string;
    environmentKey: string;
    startsWith?: string;
    limit?: number;
  }) => ({
    queryKey: [projectKey, environmentKey, startsWith, limit],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(
        getProductAnalyticsMetrics({ projectKey, environmentKey, query: { startsWith, limit } }, { signal }),
      ),
  }),
  metricDimensions: ({
    projectKey,
    environmentKey,
    metricKey,
    startsWith,
    limit = 20,
  }: {
    projectKey: string;
    environmentKey: string;
    metricKey: string;
    startsWith?: string;
    limit?: number;
  }) => ({
    queryKey: [projectKey, environmentKey, metricKey, startsWith, limit],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(
        getProductAnalyticsMetricDimensions(
          { projectKey, environmentKey, metricKey, query: { startsWith, limit } },
          { signal },
        ),
      ),
  }),
  metricDimensionValues: ({
    contextKindKey,
    dimensionKey,
    environmentKey,
    limit = 20,
    metricKey,
    projectKey,
    startsWith,
  }: {
    contextKindKey?: string;
    dimensionKey: string;
    environmentKey: string;
    limit?: number;
    metricKey: string;
    projectKey: string;
    startsWith?: string;
  }) => ({
    queryKey: [projectKey, environmentKey, metricKey, contextKindKey, dimensionKey, startsWith, limit],
    queryFn: async ({ signal }) =>
      contextKindKey
        ? reactQueryResponseAdapter(
            getProductAnalyticsContextsDimensionValues(
              {
                projectKey,
                environmentKey,
                metricKey,
                contextKindKey,
                dimensionKey,
                query: { startsWith, limit },
              },
              {
                signal,
              },
            ),
          )
        : reactQueryResponseAdapter(
            getProductAnalyticsEventsDimensionValues(
              { projectKey, environmentKey, metricKey, dimensionKey, query: { startsWith, limit } },
              { signal },
            ),
          ),
  }),
});

export const bigSegmentStore = createQueryKeys('bigSegmentStore', {
  list: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getBigSegmentStoreIntegrations({}, { signal })),
  },
  detail: ({
    projectKey,
    environmentKey,
    integrationKey,
    integrationId,
  }: {
    projectKey: string;
    environmentKey: string;
    integrationKey: string;
    integrationId: string;
  }) => ({
    queryKey: [projectKey, environmentKey, integrationKey, integrationId],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(
        getBigSegmentStoreIntegration({ projectKey, environmentKey, integrationKey, integrationId }, { signal }),
      ),
  }),
});

export const flagTemplates = createQueryKeys('flag-templates', {
  list: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getFeatureFlagTemplates({}, { signal })),
  },
});

export const internalFlagTemplates = createQueryKeys('internal-flag-templates', {
  list: ({ projKey }: { projKey: string }) => ({
    queryKey: [projKey],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(getFeatureFlagTemplatesForProject({ projKey }, { signal })),
  }),
});

export const internalProjectsWithFlagCount = createQueryKeys('internalProjectsWithFlagCount', {
  list: ({ params }: { params?: GetProjectsParams }) => ({
    // Working around some limitations of query-key-factory here.
    //
    // [params] | null is not a valid type for queryKey, so we add a
    // placeholder string ('list') to satisfy the types.
    queryKey: params ? ['list', params] : ['list'],
    queryFn: async () => getInternalProjectsWithFlagCount({ params }),
  }),
});

export const tags = createQueryKeys('tags', {
  list: ({ params }: { params?: GetTagsParams }) => ({
    // Working around some limitations of query-key-factory here.
    //
    // [params] | null is not a valid type for queryKey, so we add a
    // placeholder string ('list') to satisfy the types.
    queryKey: params ? ['collection', params] : ['collection'],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getTags({ query: params }, { signal })),
  }),
});

export const tagsList = tags.list;

export const teams = createQueryKeys('teams', {
  list: ({ params }: { params?: GetTeamsParams }) => ({
    queryKey: params ? ['collection', params] : ['collection'],
    queryFn: async ({ signal }) => getTeams({ params }, { signal }),
  }),
  detail: ({ teamKey }: { teamKey: string }) => ({
    queryKey: [teamKey],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getTeam({ teamKey }, { signal })),
  }),
});

export const teamsList = teams.list;
export const teamsDetail = teams.detail;

export const discoveredMetricEvents = createQueryKeys('discovered-metric-events', {
  list: ({
    projectKey,
    environmentKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    params?: GetDiscoveredMetricEventsParams;
  }) => ({
    queryKey: params ? [projectKey, environmentKey, params] : [projectKey, environmentKey],
    queryFn: async () => getDiscoveredMetricEvents({ projectKey, environmentKey, params }),
  }),
});

export const contexts = createQueryKeys('contexts', {
  list: ({
    projectKey,
    environmentKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    params: PostContextsParams;
  }) => ({
    queryKey: [projectKey, environmentKey, params],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(searchContexts({ projectKey, environmentKey, body: params }, { signal })),
  }),
});

export const searchContextInstancesQuery = createQueryOptions(searchContextInstances);
export const contextInstanceQuery = createQueryOptions(getContextInstances);
export const getContextInstanceSegmentMembershipQuery = createQueryOptions(getContextInstanceSegmentsMembershipByEnv);

export const contextKinds = createQueryKeys('contextKinds', {
  list: ({ projectKey }: { projectKey: string }) => ({
    queryKey: [projectKey],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getContextKindsByProjectKey({ projectKey }, { signal })),
  }),
});

export const workflows = createQueryKeys('workflows', {
  detail: ({
    projectKey,
    featureFlagKey,
    environmentKey,
    workflowId,
  }: {
    projectKey: string;
    featureFlagKey: string;
    environmentKey: string;
    workflowId: string;
  }) => ({
    queryKey: [projectKey, featureFlagKey, environmentKey, workflowId],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(
        getCustomWorkflow({ projectKey, featureFlagKey, environmentKey, workflowId }, { signal }),
      ),
  }),
});

export const integrationManifests = createQueryKeys('integrationManifests', {
  list: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getIntegrationManifests({}, { signal })),
  },
});

export const featurePreviews = createQueryKeys('featurePreviews', {
  list: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getFeaturePreviewEnrollments({}, { signal })),
  },
});

export const eventActivity = createQueryKeys('eventActivity', {
  byEventKey: ({ projectKey, eventKey, params }) => ({
    queryKey: [projectKey, eventKey, params],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(getEventActivity({ projectKey, eventKey, query: params }, { signal })),
  }),
});

export const eventActivityByEnv = createQueryKeys('eventActivityByEnv', {
  byEnvKey: ({ projectKey, environmentKey, params }) => ({
    queryKey: [projectKey, environmentKey, params],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(
        getEventActivityByEnvironment({ projectKey, environmentKey, query: params }, { signal }),
      ),
  }),
});

export const eventActivityByProj = createQueryKeys('eventActivityByProj', {
  byProjKey: ({ projectKey, params }) => ({
    queryKey: [projectKey, params],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(getEventActivityByProject({ projectKey, query: params }, { signal })),
  }),
});

export const approvalRequests = createQueryKeys('approvalRequests', {
  list: ({ params }: { params?: GetApprovalRequestsQueryParams }) => ({
    queryKey: params ? ['filtered', params] : ['all'],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getApprovalRequests({ query: params }, { signal })),
  }),
  detail: ({ approvalRequestId }: { approvalRequestId: string }) => ({
    queryKey: [{ approvalRequestId }],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getApprovalRequest({ id: approvalRequestId }, { signal })),
  }),
});

export const approvalRequestsList = approvalRequests.list;
export const approvalRequestsDetail = approvalRequests.detail;

export const sessionReplay = createQueryKeys('sessionReplay', {
  list: ({ projectKey, environmentKey }: { projectKey: string; environmentKey: string }) => ({
    queryKey: [projectKey, environmentKey],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(internalGetSessionReplaySessions({ projectKey, environmentKey }, { signal })),
  }),
  detail: ({
    projectKey,
    environmentKey,
    sessionId,
  }: {
    projectKey: string;
    environmentKey: string;
    sessionId: string;
  }) => ({
    queryKey: [projectKey, environmentKey, sessionId],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(
        internalGetSessionReplaySessionDetails({ projectKey, environmentKey, sessionId }, { signal }),
      ),
  }),
});

export const monitoringIssuesList = createQueryOptions(getIssues);
export const monitoringIssuesDetail = createQueryOptions(getIssue);

export const monitoringErrorsList = createQueryOptions(getIssueErrors);

export const flagImport = createQueryKeys('flagImport', {
  list: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getFlagImportConfigurations({}, { signal })),
  },
  detail: ({
    projectKey,
    integrationKey,
    integrationId,
  }: {
    projectKey: string;
    integrationKey: string;
    integrationId: string;
  }) => ({
    queryKey: [projectKey, integrationKey, integrationId],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(getFlagImportConfiguration({ projectKey, integrationKey, integrationId }, { signal })),
  }),
});

export const projectsQuery = createQueryOptions(generatedGetProjects);

export const queries = mergeQueryKeys(
  approvalRequests,
  account,
  actions,
  applications,
  auditLog,
  codeReferenceStatistics,
  discoveredMetricEvents,
  environments,
  experiments,
  holdouts,
  flags,
  measuredRollouts,
  members,
  metrics,
  metricGroups,
  migrationMetrics,
  migrationSafetyIssues,
  projects,
  releasePipelines,
  selectedEnvironments,
  bigSegmentStore,
  payloadFilter,
  flagTemplates,
  internalFlagTemplates,
  segments,
  tags,
  teams,
  contexts,
  contextKinds,
  ssoDomains,
  workflows,
  followPreferences,
  integrationManifests,
  shortcuts,
  flagImport,
);
