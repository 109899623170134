import { useEffect } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { trackError } from '@gonfalon/telemetry';

function wrapRouteError(error: unknown) {
  if (isRouteErrorResponse(error)) {
    const responseError = new Error(`${error.status} ${error.statusText}`);
    responseError.name = 'ReactRouterResponseError';
    responseError.cause = error;

    return responseError;
  }

  const routeError = new Error(error instanceof Error ? error.message : undefined);
  routeError.name = 'ReactRouterError';
  routeError.cause = error;

  return routeError;
}

/**
 * Wrapper around `useRouteError` hook that sends the error to Datadog.
 * @returns Route error object
 */
export function useTrackRouteError({ isCrash }: { isCrash?: boolean } = {}) {
  const error = useRouteError();

  useEffect(() => {
    if (!error) {
      return;
    }

    trackError(wrapRouteError(error), { isCrash });
  }, [error, isCrash]);

  return error;
}
