import { createQueryHook } from './internal/createQueryHook';
import { experiments } from './internal/queries';
import { GetExperimentResultsParams } from './internal/types';

export const useExperimentResults = createQueryHook(
  ({
    projectKey,
    environmentKey,
    experimentKey,
    metricKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    experimentKey: string;
    metricKey: string;
    params?: GetExperimentResultsParams;
  }) => experiments.detail({ projectKey, environmentKey, experimentKey })._ctx.results({ metricKey, params }),
);
