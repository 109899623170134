import { getClient, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export type CreateLayerInput = schemas['LayerPost'];

export async function postLayer({ projectKey, input }: { projectKey: string; input: CreateLayerInput }) {
  return reactQueryResponseAdapter(
    getClient().POST('/api/v2/projects/{projectKey}/layers', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey },
      },
      body: input,
    }),
  );
}
